var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ChatBubble", {
    attrs: {
      message: _vm.message.message,
      attachments: _vm.message.attachment,
      "sent-date-unix": _vm.message.sentat,
      "communication-role": _vm.communicationRole,
      "avatar-class": `${_vm.avatarDetails.class} tw-text-white`,
      "bubble-background-class": _vm.bubbleBackgroundColorClass,
      "sender-name": _vm.senderName,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "avatar",
      fn: function () {
        return [_vm.member.profileUrl ? _c("CoreAvatar", {
          attrs: {
            src: _vm.member.profileUrl
          }
        }) : _c("CoreAvatar", {
          staticClass: "tw-text-white",
          attrs: {
            text: _vm.avatarDetails.text,
            color: _vm.avatarDetails.color
          }
        })];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_vm.message.messageroutedto ? _c("CoreTooltip", {
          attrs: {
            text: "This is a routed message",
            placement: "bottom"
          }
        }, [_c("IconRouted", {
          staticClass: "tw-text-[12px]"
        })], 1) : _vm._e()];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };