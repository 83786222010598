import { UserConfigrations } from '@/services/user-configurations.service.js';

export default {
  namespaced: true,
  state: {
    patientOutreachFilters: {}
  },
  mutations: {
    SET_STATE (state, payload) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    }
  },
  actions: {
    async setPatientOutreachFilters (_ctx, payload) {
      await UserConfigrations.setPatientOutreachFilters(payload);
    },
    async getPatientOutreachFilters ({ commit }) {
      try {
        const { data } = await UserConfigrations.getPatientOutreachFilters();

        commit('SET_STATE', {
          patientOutreachFilters: data
        })

        return data
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
  },
};
