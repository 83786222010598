import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class PrescriptionService extends BaseService {
  static async getPrescription (id) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/prescriptionOrder/full/${id}`
      );
      
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async retryPayment (prescriptionid) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/prescriptionOrder/${prescriptionid}/retrypayment`
      );
      
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPrescriptionForCancellation (id) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/patient/${id}/prescriptionforcancellation`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPrescriptionForRefund (id, prescriptionid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/patient/${id}/prescriptionforcancellation/${prescriptionid}`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async cancelSinglePrescription (id, body) {
    try {
      const response = await this.request({ auth: true }).delete(
        `/transact/prescriptionorder/${id}/cancel`,
        {
          data: {
            ...body,
          },
        }
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPrescriptionHistory (id) {
    try {
      const response = await this.request({ auth: true }).get(
        `/transact/prescriptionorder/${id}/history`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async pausePrescription (prescriptionorderid, data) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/prescriptionOrder/${prescriptionorderid}/pause`,
        data
      );
      
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async resumePrescription (prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/transact/prescriptionorder/${prescriptionorderid}/resume`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async stopTimerDelay (patientid, prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/stopdelay`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async bypassDelay (prescriptionorderid) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/prescriptionOrder/${prescriptionorderid}/sendorder`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async pushEscript (prescriptionorderid) {
    try {
      const response = await this.request({ auth: true }).post(
        `/dosespot/${prescriptionorderid}/resend`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async sendReplacement (prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true }).post(
        `/dosespot/${prescriptionorderid}/sendreplacement`,
        payload
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateShipmentDate (patientid, prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true }).put(
        `/prescriptionorder/${patientid}/${prescriptionorderid}/update`,
        payload
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updatePrescriptionStartDate (prescriptionorderid, payload) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/prescriptionOrder/${prescriptionorderid}/reschedule`,
        payload
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async activateRefill (prescriptionorderid) {
    try {
      const response = await this.request({ auth: true, v2: true }).put(
        `/prescriptionOrder/${prescriptionorderid}/paynextinvoice`
      );
      
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async restartTreatment (patientid, prescriptionorderid, payload) {
    const response = await this.request({ auth: true }).put(
      `/prescriptionorder/${patientid}/${prescriptionorderid}/restart`,
      payload
    );
      
    return new ResponseWrapper(response, response.data);
  }

  static async pushScheduledPrescription (
    patientid,
    prescriptionorderid
  ) {
    const response = await this.request({ auth: true }).put(
      `/prescriptionorder/${patientid}/${prescriptionorderid}/prescribe`
    );
      
    return new ResponseWrapper(response, response.data);
  }

  static async cancelPendingOrder (prescriptionorderid) {
    const response = await this.request({ auth: true }).delete(
      `/transact/prescriptionorder/${prescriptionorderid}/cancelorder`
    );
      
    return new ResponseWrapper(response, response.data);
  }

  static async getProductDosageAndUnit (patientid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/product/patient/${patientid}/dosage`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
