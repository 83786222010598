import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class ArticleService extends BaseService {
  static async getArticles (params) {
    try {
      /*
        params: {
          limit: 20,
          q: 'search query',
          offset: 0
        }
      */
      const response = await this.request({ auth: true, v2: true }).get('/intercom/articles', {
        params 
      });
      
      return response.data;
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getArticleAdmins () {
    try {
      const response = await this.request({ auth: true, v2: true }).get('/intercom/admins');
      
      return response.data;
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async syncArticles () {
    try {
      const response = await this.request({ auth: true, v2: true }).patch('/intercom/articles/sync');

      return response.data
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async syncAdmins () {
    try {
      const response = await this.request({ auth: true, v2: true }).patch('/intercom/admins/sync');

      return response.data;
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getArticlesById (articles) {
    try {
      const response = await this.request({ auth: true, v2: true }).post(
        '/intercom/articles',
        { articleIds: articles }
      );
      
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}