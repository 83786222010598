import { AdminsService } from '@/services/admins.service.js';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      alert: {
        show: false,
        message: ''
      },
      showDialog: false,
      selectedCampaign: null,
      campaigns: ['Follow Up'],
      buttonDisabled: false
    };
  },
  watch: {
    showDialog() {
      this.alert.show = false;
      this.selectedCampaign = null;
      this.buttonDisabled = false;
    }
  },
  methods: {
    async onRemoveFromCampaign() {
      if (!this.selectedCampaign) {
        this.alert.message = 'Please fill in all the fields.';
        this.alert.show = true;
        return;
      }
      try {
        this.buttonDisabled = true;
        const {
          data
        } = await AdminsService.removeFromCampaign(this.rowData.id);
        console.log(data);
        if (data) {
          this.showDialog = false;
        }
      } catch (err) {
        this.alert.message = err;
        this.alert.show = true;
      } finally {
        this.buttonDisabled = false;
      }
    }
  }
};