/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */
import axios from 'axios';
import { AuthService } from '@/services/auth.service';

const RefreshManager = {
  isRefreshing: false,
  refreshPromise: null,

  async refreshTokens () {
    if (this.isRefreshing) {
      return this.refreshPromise;
    }

    this.isRefreshing = true;

    this.refreshPromise = AuthService.refreshTokens()
      .catch(error => {
        console.error('Token refresh failed:', error);
        throw error;
      })
      .finally(() => {
        this.isRefreshing = false;
        this.refreshPromise = null;
      });

    return this.refreshPromise;
  },
};

export class Http {
  constructor (status) {
    this.isAuth = status?.auth || false;

    let url;
    if (process.env.VUE_APP_MESSAGING_VERSION === 'v2') {
      if (status.v3) {
        url = process.env.VUE_APP_WEBSOCKET_URL;
      } else if (status.v2) {
        url = process.env.VUE_APP_API_URL_V2;
      } else {
        url = process.env.VUE_APP_API_URL;
      }
    } else {
      if (status.v2) {
        url = process.env.VUE_APP_API_URL_V2;
      } else {
        url = process.env.VUE_APP_API_URL;
      }
    }

    this.instance = axios.create({
      baseURL: status.baseUrl || url,
    });
    
    return this.init();
  }

  init () {
    if (this.isAuth) {
      this.instance.interceptors.request.use(
        async request => {
          await this.refreshTokenIfNeeded(request);
          request.headers.authorization = AuthService.getBearer();
          
          return request;
        },
        error => Promise.reject(error)
      );

      this.instance.interceptors.response.use(
        response => response,
        async error => {
          const originalRequest = error.config;

          if (
            error.response &&
            error.response.status === 401 &&
            !originalRequest._retry &&
            ['The incoming token has expired', 'Expired token'].includes(
              error.response.data.message
            )
          ) {
            originalRequest._retry = true;
            try {
              await this.refreshTokenIfNeeded();
              originalRequest.headers.authorization = AuthService.getBearer();
              
              return this.instance(originalRequest);
            } catch (refreshError) {
              return Promise.reject(refreshError);
            }
          }

          return Promise.reject(error);
        }
      );
    }

    return this.instance;
  }

  async refreshTokenIfNeeded () {
    const tokenExpiry = AuthService.getTokenExpiry();
    const currentTime = Math.floor(Date.now() / 1000);

    if (tokenExpiry - currentTime > 3600) {
      return;
    }

    await RefreshManager.refreshTokens();
  }
}