var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("BaseSlidePanel", {
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, [_c("div", {
    staticClass: "tw-relative w-full tw-h-full tw-flex tw-flex-col"
  }, [_c("div", {
    staticClass: "tw-grow tw-p-5 tw-flex tw-flex-col tw-gap-5",
    attrs: {
      id: "patients-side-panel-filter-content"
    }
  }, [_c("div", {
    staticClass: "tw-flex tw-justify-end tw-items-center"
  }, [_c("b-icon", {
    staticClass: "tw-cursor-pointer",
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    },
    on: {
      click: function ($event) {
        _vm.model = false;
      }
    }
  })], 1), _vm.selectedFilter ? _c("PatientsSidePanelFilterContent", {
    attrs: {
      filter: _vm.selectedFilter
    },
    on: {
      close: function ($event) {
        _vm.selectedFilter = null;
      }
    },
    model: {
      value: _vm.filterObject,
      callback: function ($$v) {
        _vm.filterObject = $$v;
      },
      expression: "filterObject"
    }
  }) : [_c("div", {
    staticClass: "tw-flex tw-justify-between tw-items-center tw-mb-5"
  }, [_c("div", [_vm._v(" Add Filters ")]), _c("div", {
    staticClass: "tw-text-cumin tw-cursor-pointer",
    on: {
      click: _vm.clearAll
    }
  }, [_c("span", [_vm._v("Clear all")])])]), _c("ul", {
    staticClass: "tw-flex tw-flex-col tw-gap-5"
  }, _vm._l(_vm.filters, function (filter) {
    return _c("li", {
      key: filter.field,
      staticClass: "tw-flex tw-gap-5 tw-justify-between tw-items-center tw-cursor-pointer",
      on: {
        click: function ($event) {
          _vm.selectedFilter = filter;
        }
      }
    }, [_c("span", {
      staticClass: "tw-text-eclipse"
    }, [_vm._v(" " + _vm._s(filter.label) + " ")]), _c("div", {
      staticClass: "tw-flex tw-items-center tw-gap-2"
    }, [_c("span", {
      staticClass: "tw-text-secondary-gray"
    }, [_vm._v(" " + _vm._s(_vm.filterObjectPreviews[filter.field]) + " ")]), _c("IconChevron", {
      staticClass: "tw-rotate-180 tw-text-secondary-grayx"
    })], 1)]);
  }), 0)]], 2), _c("div", {
    staticClass: "tw-bg-light-gray tw-p-5 tw-flex tw-items-center tw-gap-2 tw-shadow-lg tw-justify-stretch",
    attrs: {
      id: "patients-side-panel-filter-footer"
    }
  }, [_c("BaseButton", {
    staticClass: "tw-flex-1",
    attrs: {
      color: "eclipse",
      outline: "",
      rounded: "lg"
    },
    on: {
      click: _vm.onCancel
    }
  }, [_vm._v(" Cancel ")]), _c("BaseButton", {
    staticClass: "tw-flex-1",
    attrs: {
      color: "cumin",
      rounded: "lg"
    },
    on: {
      click: _vm.onSubmitFilter
    }
  }, [_vm._v(" Apply ")])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };