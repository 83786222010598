import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

// * Note: I made it a separate service so that the code
// * isn't all crammed into the patient service file.

export class ActionService extends BaseService {
  static async getStatusCount () {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        '/admin/patientDashboard/counts'
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getAdminPatientList (type, nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/${type}?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/${type}`
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getActivePatients (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/active?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/active'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFailedTransactions (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/failedtransaction?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/failedtransaction'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getNeedsPatientReply (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/needspatientreply?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/needspatientreply'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFollowUpStart (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/followupstart?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/followupstart'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFollowUpCompletion (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/followupcompletion?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/followupcompletion'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFullyCancelled (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/fullycancelled?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/fullycancelled'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getOnboarding (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/onboarding?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/onboarding'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getVerificationRequired (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/verificationrequired?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/verificationrequired'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getDoctorRequest (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/doctorrequest?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/doctorrequest'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getRefundRequest (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/refundrequest?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/refundrequest'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getNotAccepted (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/notaccepted?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/notaccepted'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getInactivePatients (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/inactive?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/inactive'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getTransferRxPatients (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/admin/patient/status/transferrx?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/admin/patient/status/transferrx'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getActionNote (id) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/patient/${id}/actionnotes`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async addActionNote (id, body) {
    try {
      // ? Accepted actionids:
      // * failedtransaction
      // * needspatientreply
      // * followupcompletion
      // * fullycancelled
      // * active
      const response = await this.request({ auth: true }).post(
        `/admin/patient/${id}/actionnotes`,
        body
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async dequeueItem (id, tab) {
    try {
      const response = await this.request({ auth: true }).post(
        `/admin/patient/status/${tab}/patient/${id}`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async dequeueType (id, type) {
    try {
      const response = await this.request({ auth: true }).post(
        `/admin/patient/status/${type}/prescription/${id}`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getRemovedPatients (nextpage = '') {
    try {
      let response;
      if (nextpage) {
        response = await this.request({ auth: true }).get(
          `/patient/account/deleted?nextpage=${nextpage}`
        );
      } else {
        response = await this.request({ auth: true }).get(
          '/patient/account/deleted'
        );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
