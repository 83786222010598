var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "my-3 mx-2",
    attrs: {
      id: "article-dialog",
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-stacking": "",
      size: "lg"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("div", {
    staticClass: "tw-border-0 tw-border-b tw-border-solid tw-border-slate-300"
  }, [_c("div", {
    staticClass: "tw-flex tw-justify-between"
  }, [_c("div", {
    staticClass: "tw-flex tw-flex-1 tw-items-center"
  }, [_c("div", {
    staticClass: "tw-px-3 tw-py-2"
  }, [_c("IconArticle", {
    staticClass: "tw-text-muted",
    attrs: {
      width: "20",
      height: "25"
    }
  })], 1), _c("b-form-input", {
    ref: "searchArticlesInput",
    staticClass: "h2 tw-border-none tw-pt-0 tw-pl-3 tw-mt-1.5 tw-max-full tw-pb-0 placeholder:tw-text-slate-400 placeholder:tw-text-xl placeholder:tw-capitalize",
    attrs: {
      id: "search",
      placeholder: "Search Articles"
    },
    on: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchArticles.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c("div", {
    staticClass: "tw-flex tw-items-center tw-px-3 tw-py-2"
  }, [_vm.refreshStatus === "idle" ? _c("b-icon", {
    staticClass: "tw-cursor-pointer text-muted",
    attrs: {
      icon: "arrow-clockwise",
      "font-scale": "1.75"
    },
    on: {
      click: _vm.refreshArticles
    }
  }) : _vm.refreshStatus === "loading" ? _c("b-spinner", {
    attrs: {
      small: "",
      variant: "muted"
    }
  }) : _c("div", [_c("span", [_c("b-icon", {
    staticClass: "pt-3",
    attrs: {
      icon: "check",
      "font-scale": "2",
      variant: "success"
    }
  }), _c("span", {
    staticClass: "text-success"
  }, [_vm._v("Success!")])], 1)])], 1)])]), _c("div", {
    staticClass: "tw-flex tw-h-[90%]"
  }, [_c("div", {
    staticClass: "tw-h-full tw-border-0 tw-border-r tw-border-solid tw-border-slate-300 pt-3 tw-w-2/5"
  }, [_c("perfect-scrollbar", {
    staticClass: "ps",
    staticStyle: {
      "max-height": "50vh"
    },
    attrs: {
      options: _vm.scrollbarOptions,
      "infinite-wrapper": ""
    }
  }, [_vm.displayArticles.length == 0 && _vm.search.length > 0 ? _c("p", {
    staticClass: "tw-mt-2 tw-pl-4"
  }, [_vm._v(" No results found. ")]) : _c("span", {
    staticClass: "tw-text-muted text-small ml-2 pl-3"
  }, [_vm._v("Winona Help Center")]), _vm._l(_vm.displayArticles, function (article) {
    return _c("div", {
      key: article.id,
      class: [_vm.loading ? "tw-text-slate-500 tw-cursor-wait" : "hover:tw-bg-[#f1f1f1] tw-cursor-pointer"],
      on: {
        mouseover: function ($event) {
          return _vm.setActiveItem(article);
        },
        click: _vm.sendToChat
      }
    }, [_c("div", {
      staticClass: "text-truncate tw-px-4 tw-py-2.5"
    }, [_vm._v(" " + _vm._s(article.title) + " ")])]);
  }), _vm.search.length === 0 ? _c("InfiniteLoading", {
    attrs: {
      identifier: _vm.infiniteTag
    },
    on: {
      infinite: _vm.loadMoreArticles
    },
    scopedSlots: _vm._u([{
      key: "no-more",
      fn: function () {
        return [_c("div", [_vm._v(" All articles are loaded. ")])];
      },
      proxy: true
    }], null, false, 1078634049)
  }) : _vm._e()], 2)], 1), _c("div", {
    staticClass: "tw-w-3/5 tw-px-4 tw-h-100"
  }, [_c("div", {
    staticClass: "tw-text-muted text-small ml-2 pt-3"
  }, [_vm._v(" Preview ")]), _vm.loading ? _c("div", [_c("b-skeleton", {
    staticClass: "tw-h-8 tw-my-4"
  }), _c("b-skeleton", {
    attrs: {
      width: "85%"
    }
  }), _c("b-skeleton", {
    attrs: {
      width: "60%"
    }
  }), _c("b-skeleton", {
    attrs: {
      width: "75%"
    }
  })], 1) : _c("perfect-scrollbar", {
    staticClass: "ps tw-flex tw-flex-col",
    staticStyle: {
      "max-height": "50vh"
    },
    attrs: {
      options: _vm.scrollbarOptions
    }
  }, [_c("h1", {
    staticClass: "mt-4 tw-text-[26px] tw-cursor-pointer"
  }, [_c("a", {
    attrs: {
      href: _vm.selectedArticle.url,
      target: "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedArticle.title) + " ")])]), _vm.selectedArticle.author_id ? _c("div", [_vm._v(" Written by "), _c("b", [_vm._v(_vm._s(_vm.getAdminName(_vm.selectedArticle.author_id)))])]) : _vm._e(), _vm.selectedArticle.updated_at ? _c("div", {
    staticClass: "text-small tw-mb-5"
  }, [_vm._v(" Updated " + _vm._s(_vm.formatDate(_vm.selectedArticle.updated_at * 1000, "MM/DD/YYYY")) + " ")]) : _vm._e(), _vm.selectedArticle.body ? _c("div", {
    directives: [{
      name: "html-safe",
      rawName: "v-html-safe",
      value: _vm.parseHtml(_vm.selectedArticle.body),
      expression: "parseHtml(selectedArticle.body)"
    }],
    staticClass: "preview pb-3"
  }) : _vm._e()])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };