import $cookies from 'vue-cookies';

import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

import $router from '../router';
import store from '../store';

if (process.env.VUE_APP_SERVER == 'local') {
  $cookies.config(604800, '/');
} else {
  $cookies.config(604800, null, null, true);
}

export class AuthService extends BaseService {
  static async login (data) {
    try {
      const response = await this.request({ auth: false }).post(
        '/signin',
        data
      );
      if (response?.data?.body?.idToken) {
        _setAuthData({
          accessToken: response.data.body.idToken,
          exp: _parseTokenData(response.data.body.idToken).exp,
        });
        this.request({ auth: true })
          .get('user')
          .then(response =>
            localStorage.setItem('userid', response.data.body.id)
          );
      }
      
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async signup (data) {
    try {
      const response = await this.request({ auth: false }).post(
        '/signup',
        data
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateTemporaryPassword (data) {
    try {
      const response = await this.request({ auth: false }).put(
        '/user/updatetemporarypassword',
        data
      );
      if (response?.data?.body?.idToken) {
        _setAuthData({
          accessToken: response.data.body.idToken,
          exp: _parseTokenData(response.data.body.idToken).exp,
        });
        this.request({ auth: true })
          .get('user')
          .then(response =>
            localStorage.setItem('userid', response.data.body.id)
          );
      }
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async logout (payload) {
    try {
      let response
      const refreshToken = localStorage.getItem('refreshToken');
      if (refreshToken) {
        const tokenUrl = `https://${process.env.VUE_APP_COGNITO_USER_POOL_DOMAIN}/oauth2/revoke`;
        const body = new URLSearchParams();
        body.append('client_id', process.env.VUE_APP_COGNITO_CLIENT_ID);
        body.append('token', refreshToken);
    
        const basicAuth = btoa(`${process.env.VUE_APP_COGNITO_CLIENT_ID}:${process.env.VUE_APP_COGNITO_CLIENT_SECRET}`);
  
        response = await fetch(tokenUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${basicAuth}`
          },
          body: body.toString()
        });
      }
      
      _resetAuthData();

      const logoutUrl = `https://${process.env.VUE_APP_COGNITO_USER_POOL_DOMAIN}/logout?client_id=${process.env.VUE_APP_COGNITO_CLIENT_ID}&logout_uri=${window.location.origin}/login${payload?.hasError ? '?error=code_exchange_failed' : ''}`;
      window.location = logoutUrl;
      
      return new ResponseWrapper(response, response && response?.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async refreshTokens () {  
    const tokenUrl = `https://${process.env.VUE_APP_COGNITO_USER_POOL_DOMAIN}/oauth2/token`;
    const body = new URLSearchParams();
    body.append('grant_type', 'refresh_token');
    body.append('client_id', process.env.VUE_APP_COGNITO_CLIENT_ID);
    body.append('refresh_token', localStorage.getItem('refreshToken'));
  
    const basicAuth = btoa(`${process.env.VUE_APP_COGNITO_CLIENT_ID}:${process.env.VUE_APP_COGNITO_CLIENT_SECRET}`);
    const response = await fetch(tokenUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${basicAuth}`
      },
      body: body.toString()
    });
  
    const data = await response.json();
    const { id_token, error } = data;

    if (error === 'invalid_grant') {
      _resetAuthData();
      $router.push({ name: 'login' }).catch(() => {});
      
      return
    }
  
    if (id_token) {
      _setAuthData({ accessToken: id_token });  
    }
  }

  static hasRefreshToken () {
    return Boolean($cookies.get('refreshToken'));
  }

  static setRefreshToken (status) {
    if (!['', 'true'].includes(status)) {
      throw new Error(
        `setRefreshToken: invalid value ${status}; Expect one of ['', 'true']`
      );
    }

    $cookies.set('refreshToken', status);
  }

  static getBearer () {
    return `Bearer ${$cookies.get('accessToken')}`;
  }

  static setBearer (accessToken) {
    console.log('accessToken', accessToken);
    $cookies.set('accessToken', accessToken);
  }

  static async getCurrent () {
    try {
      const response = await this.request({ auth: true }).get('user');
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static getTokenExpiry () {
    return _parseTokenData($cookies.get('accessToken')).exp;
  }
}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _parseTokenData (accessToken) {
  let payload = '';
  let tokenData = {};

  try {
    payload = accessToken.split('.')[1];
    tokenData = JSON.parse(atob(payload));
  } catch (error) {
    throw new Error(error);
  }

  return tokenData;
}

export async function _resetAuthData () {
  $cookies.keys().forEach(cookie => $cookies.remove(cookie));
  
  localStorage.clear();
  store.dispatch('auth/resetState');
}

export function _setAuthData ({ accessToken, refreshToken } = {}) {
  AuthService.setRefreshToken('true');
  AuthService.setBearer(accessToken);
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('token', `Bearer ${accessToken}`);

  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  }
}
