import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { _setAuthData, AuthService } from '@/services/auth.service';
export default {
  async mounted() {
    await this.exchangeCodeForToken(this.$route.query.code);
  },
  methods: {
    async exchangeCodeForToken(authorizationCode) {
      if (!authorizationCode) {
        await AuthService.logout({
          hasError: true
        });
        this.$router.push('/login?error=code_exchange_failed');
        return;
      }
      const redirectUri = `${location.origin}/code-exchange`;
      const tokenUrl = `https://${process.env.VUE_APP_COGNITO_USER_POOL_DOMAIN}/oauth2/token`;
      const body = new URLSearchParams();
      body.append('grant_type', 'authorization_code');
      body.append('client_id', process.env.VUE_APP_COGNITO_CLIENT_ID);
      body.append('code', authorizationCode);
      body.append('redirect_uri', redirectUri);
      const basicAuth = btoa(`${process.env.VUE_APP_COGNITO_CLIENT_ID}:${process.env.VUE_APP_COGNITO_CLIENT_SECRET}`);
      try {
        const response = await fetch(tokenUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${basicAuth}`
          },
          body: body.toString()
        });
        const data = await response.json();
        const {
          access_token,
          id_token,
          refresh_token,
          error
        } = data;
        if (error) {
          await AuthService.logout({
            hasError: true
          });
          this.$router.push('/login?error=code_exchange_failed');
          return;
        }
        if (id_token) {
          _setAuthData({
            accessToken: id_token,
            refreshToken: refresh_token
          });
          this.$router.push('/');
        }
        return {
          access_token,
          id_token,
          refresh_token
        };
      } catch (error) {
        console.error('Error exchanging authorization code:', error);
        await AuthService.logout({
          hasError: true
        });
        this.$router.push('/login?error=code_exchange_failed');
      }
    }
  }
};