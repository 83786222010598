import { messageHeightMixin } from '@/mixins/messageHeightMixin';
export default {
  name: 'MessagesView',
  mixins: [messageHeightMixin],
  watch: {
    '$route.query.patientid': {
      async handler(patientId) {
        const {
          $store
        } = this;
        $store.commit('message/RESET_STATE');
        if (patientId) {
          const {
            items,
            nextPage
          } = await $store.dispatch('message/getSingleConversationList', {
            patientid: patientId
          });
          if (items !== null && items !== void 0 && items.length) {
            $store.commit('message/SET_STATE', {
              conversationPages: [{
                items,
                nextPage
              }],
              conversationList: items
            });
          }
          return;
        }
        const {
          items,
          nextPage
        } = await $store.dispatch('message/getConversationList');
        if (items !== null && items !== void 0 && items.length) {
          $store.commit('message/SET_STATE', {
            conversationPages: [{
              items,
              nextPage
            }],
            conversationList: items
          });
        }
      },
      immediate: true
    }
  }
};