import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
// libs
import jwt from 'jsonwebtoken';
import { fromUnixTime } from 'date-fns';

// services
import { RequestService } from '@/services/request.service';

// components
import ChatBubble from '@corefront/components-v2/Chat/ChatBubble';
import ChatThread from '@corefront/components-v2/Chat/ChatThread';

// constants
import { COMMUNICATION_ROLE, MESSAGE_SEND_STATUS } from '@corefront/constant/messages';
export default {
  components: {
    ChatThread,
    ChatBubble
  },
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    },
    showMessageButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      processing: false,
      showDialog: false,
      forwardedMessageData: {},
      adminNotes: '',
      isLoading: false,
      alert: {
        show: false,
        message: ''
      }
    };
  },
  computed: {
    MESSAGE_SEND_STATUS: () => MESSAGE_SEND_STATUS,
    profileUrlHashmaps() {
      return this.forwardedMessageData.messagelist.reduce((acc, curr) => {
        if (!curr.profileUrl) {
          return acc;
        }
        return {
          ...acc,
          [curr.userid]: curr.profileUrl
        };
      }, {});
    },
    namesHashmap() {
      return this.forwardedMessageData.messagelist.reduce((acc, curr) => {
        if (!curr.firstname || !curr.lastname) {
          return acc;
        }
        return {
          ...acc,
          [curr.userid]: `${curr.firstname} ${curr.lastname}`
        };
      }, {});
    },
    viewOnly() {
      return ['COMPLETED', 'DISCARDED'].includes(this.forwardedMessageData.messagestatus);
    }
  },
  watch: {
    async showDialog(show) {
      if (show) {
        await this.refreshData();
      } else {
        this.alert.show = false;
        this.adminNotes = '';
        this.forwardedMessageData = {};
      }
    }
  },
  methods: {
    getBubbleBackgroundClass(msg) {
      const role = this.getCommunicationRole(msg);
      return role === COMMUNICATION_ROLE.RECEIVER ? 'tw-bg-secondary-light' : 'tw-bg-warm-gray';
    },
    getCommunicationRole(msg) {
      return msg.userid === this.forwardedMessageData.patientid ? COMMUNICATION_ROLE.RECEIVER : COMMUNICATION_ROLE.SENDER;
    },
    chatThreadDateResolver(msg) {
      return fromUnixTime(msg.sentat);
    },
    async refreshData() {
      try {
        this.isLoading = true;
        const {
          patientid,
          id
        } = this.rowData;
        const {
          data
        } = await RequestService.getForwardedRequest(patientid, id);
        this.forwardedMessageData = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.showDialog = false;
      this.$emit('close');
    },
    getDate(date) {
      if (date == '-') {
        return '-';
      }
      return new Date(date * 1000).toDateString();
    },
    async redirectToMessages() {
      this.processing = true;
      await RequestService.openForwardToChat(this.rowData.patientid, this.rowData.id);
      this.processing = false;
      this.$router.push({
        name: 'messages',
        query: {
          patientid: this.rowData.patientid
        }
      });
    },
    async completeRequest() {
      const body = {
        adminnotes: this.adminNotes
      };
      this.processing = true;
      await RequestService.completeRequest(this.forwardedMessageData.patientid, this.forwardedMessageData.id, body);
      this.processing = false;
      this.close();
    },
    redirectToIntercom(id) {
      // ! ONLY WORKS ON PROD
      const token = jwt.sign({}, 'NTNv7j0TuYARvmNMmWXo6fKvM4o6nv/aUi9ryX38ZH+L1bkrnD1ObOQ8JAUmHCBq7Iy7otZcyAagBLHVKvvYaIpmMuxmARQ97jUVG16Jkpkp1wXOPsrF9zwew6TpczyHkHgX5EuLg2MeBuiT/qJACs1J0apruOOJCg/gOtkjB4c=', {
        header: {
          predicates: [{
            attribute: 'role',
            comparison: 'eq',
            type: 'role',
            value: 'user_role'
          }, {
            attribute: 'user_id',
            comparison: 'eq',
            type: 'string',
            value: id
          }]
        }
      });
      const toSubmit = token.split('.');
      window.open(`https://app.intercom.com/a/apps/dfvodm6i/users/segments/all-users:${toSubmit[0]}`, '_blank');
    }
  }
};