import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class AdminsService extends BaseService {
  static async getAdmins () {
    try {
      const response = await this.request({ auth: true }).get('/admin');
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async createNewAdmin (data) {
    try {
      const response = await this.request({ auth: true }).post('/admin', data);
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async updateAdminDetails (adminid, data) {
    try {
      const response = await this.request({ auth: true }).put(
        `/admin/${adminid}`,
        data
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async reportIssue (patientid, data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/admin/patient/${patientid}/report`,
        data
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async removeFromCampaign (patientid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/campaign/${patientid}/remove`
      );
      
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
