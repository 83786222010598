import patients from './patients';
import earnings from './earnings';
import message from './message';
import admins from './admins';
import auth from './auth';
import doctors from './doctors';
import inventory from './inventory';
import chat from './chat';
import tables from './tables';
import doctorMessageRouter from './doctor-message-router';
import failedMessages from './failed-messages';
import infoview from './infoview';
import articles from './articles';
import lookup from './lookup';
import userConfigurations from './user-configurations'

export default {
  userConfigurations,
  doctorMessageRouter,
  failedMessages,
  patients,
  earnings,
  message,
  admins,
  auth,
  doctors,
  inventory,
  chat,
  tables,
  infoview,
  articles,
  lookup
};
