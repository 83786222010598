import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-exception.stack.js";
// libs
import { mapState } from 'vuex';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _orderBy from 'lodash/orderBy';

// components
import TableList from '@/components/tables/list/TableList';
import Main from '@/components/tables/partials/Main';
import ActionDialog from '@/components/actions/ActionDialog';
import ActionHistory from '@/components/actions/ActionHistory';
import PatientsInfoSidePanel from '@/components/patients/PatientsInfoSidePanel';

// mixins
import { patientViewMixin } from '@/mixins/patientView';

// constants
import { TAB_ID, HEADERS_BY_TAB_ID, TABS, STATES } from '@/constants/patient';

// utils
import { tryToCatchSync } from '@corefront/utils/tryToCatch';
export default {
  components: {
    Main,
    TableList,
    // PatientInfoView,
    ActionDialog,
    ActionHistory,
    PatientsInfoSidePanel
  },
  mixins: [patientViewMixin],
  data() {
    return {
      isPatientsSidePanelFilterVisible: false,
      showTableList: true,
      search: this.$route.query.search || '',
      tab: this.$route.query.tab || TAB_ID.ALL,
      refList: {
        all: 'AllTable',
        active: 'ActiveTable',
        inactive: 'InactiveTable',
        failedtransaction: 'FailedTable',
        needspatientreply: 'NeedsReplyTable',
        followupstart: 'FollowUpStartTable',
        followupcompletion: 'FollowUpCompleteTable',
        fullycancelled: 'FullyCancelledTable',
        onboarding: 'OnboardingTable',
        verificationrequired: 'VerificationRequiredTable',
        verificationrequireddoctor: 'VerificationRequiredDoctorTable',
        doctorrequest: 'DoctorRequestTable',
        refundrequest: 'RefundRequestTable',
        notaccepted: 'NotAcceptedTable',
        'cs-hold': 'OrderHoldsTable',
        'card-expired': 'CardExpiredTable',
        transferrx: 'TransferRxTable',
        failedaddress: 'AddressVerificationRequiredTable',
        voicemail: 'VoicemailTable',
        'no-follow-up': 'NoFollowUpTable',
        banned: 'BannedTable',
        'cancelled-by-stripe': 'CancelledByStripeTable',
        'prescription-end-before-followup': 'PrescriptionEndBeforeFollowupTable',
        'script-end-before-followup-no-dhea': 'ScriptEndBeforeFollowupNoDhea',
        'card-updated-without-prescription-restart': 'CardUpdatedWithoutPrescriptionRestartTable'
      },
      selectedRow: {
        id: ''
      },
      showInfoViewModal: false,
      toast: {
        message: '',
        variant: ''
      },
      // Generic alert component
      alert: {
        show: false,
        message: ''
      }
    };
  },
  computed: {
    ...mapState('patients', ['totalPatients', 'isFetchingPatients']),
    ...mapState('userConfigurations', ['patientOutreachFilters']),
    ...mapState('lookup', ['states']),
    tabName() {
      const tab = TABS.flatMap(x => x.tabs).find(tab => tab.id === this.tab);
      return tab.text || 'All';
    },
    headers() {
      return HEADERS_BY_TAB_ID[this.tab];
    },
    isOutreachTab() {
      return [TAB_ID.OUTREACH_ABANDONED_CHECKOUT, TAB_ID.OUTREACH_ABANDONED_VERIFICATION, TAB_ID.OUTREACH_ABANDONED_TREATMENT_SELECTION, TAB_ID.OUTREACH_ABANDONED_QUESTIONNAIRE, TAB_ID.OUTREACH_CALL_SCHEDULED].includes(this.$route.query.tab);
    },
    totalFilters() {
      const [err, filters] = tryToCatchSync(() => JSON.parse(atob(this.$route.query.q)));
      if (err) {
        return 0;
      }
      return Object.values(filters).filter(f => Array.isArray(f) ? f.length > 0 : !_isNil(f)).length;
    },
    patientOutreachFilterMap() {
      const timezones = _uniq(STATES.map(state => state.timezone));
      return [{
        field: 'timezone',
        label: 'Timezone',
        type: 'multiselect',
        options: _orderBy(timezones.map(timezone => ({
          label: timezone,
          value: timezone
        })), ['label'], ['asc'])
      }, {
        field: 'state',
        label: 'States',
        type: 'multiselect',
        options: _orderBy(STATES.map(state => ({
          label: state.statename,
          value: state.code
        })), ['label'], ['asc'])
      }];
    },
    options() {
      switch (this.tab) {
        case TAB_ID.FOLLOW_UP_COMPLETION:
        case TAB_ID.FOLLOW_UP_START:
          return {
            sort: 'desc',
            sortBy: 'followupdatetime'
          };
        case TAB_ID.NEEDS_PATIENT_REPLY:
          return {
            sort: 'desc',
            sortBy: 'needspatientreplydatetime'
          };
        case TAB_ID.PAYMENT_REFUND_REQUEST:
          return {
            sort: 'desc',
            sortBy: 'timestampcancelled'
          };
        default:
          return {
            sort: 'desc',
            sortBy: 'lastupdateactivity'
          };
      }
    }
  },
  watch: {
    '$route.query'() {
      this.getPatients();
    },
    async '$route.query.q'() {
      if (!this.isOutreachTab) {
        return;
      }
      const {
        q,
        tab
      } = this.$route.query;
      const [error, filter] = tryToCatchSync(() => JSON.parse(atob(q)));
      await this.$store.dispatch('userConfigurations/setPatientOutreachFilters', {
        ...this.patientOutreachFilters,
        [tab]: error ? undefined : filter
      });
      await this.$store.dispatch('userConfigurations/getPatientOutreachFilters');
    }
  },
  async created() {
    await this.initPatientOutreachFilters();
    this.getStatusCount();
    this.loadSinglePatient();
    this.getPatients();
    this.$store.dispatch('lookup/getStates');
  },
  methods: {
    async initPatientOutreachFilters() {
      await this.$store.dispatch('userConfigurations/getPatientOutreachFilters');
      const tabFilters = this.patientOutreachFilters[this.$route.query.tab] || {};
      this.$router.push({
        query: {
          ...this.$route.query,
          q: _isEmpty(tabFilters) ? undefined : btoa(JSON.stringify(tabFilters))
        }
      });
    },
    async getPatients() {
      var _q$timezone, _q$state;
      const {
        sortBy,
        sort
      } = this.$route.query;
      const sortField = sortBy || this.options.sortBy;
      const sortDirection = sort ? sort === 'desc' ? 'DESC' : 'ASC' : this.options.sort.toUpperCase();
      const [, q] = tryToCatchSync(() => JSON.parse(atob(this.$route.query.q)));
      await this.$store.dispatch('patients/getPatients', {
        type: this.tab,
        search: this.search,
        page: this.$route.query.page || 1,
        sortDirection,
        sortField,
        timezone: (_q$timezone = q === null || q === void 0 ? void 0 : q.timezone) !== null && _q$timezone !== void 0 ? _q$timezone : undefined,
        state: (_q$state = q === null || q === void 0 ? void 0 : q.state) !== null && _q$state !== void 0 ? _q$state : undefined
      });
    },
    async onSort({
      sortBy,
      sortDesc
    }) {
      const sortField = sortBy === 'name' ? 'firstname' : sortBy;
      const sortDirection = sortDesc ? 'desc' : 'asc';
      this.$router.push({
        query: {
          ...this.$route.query,
          sortBy: sortField,
          sort: sortDirection,
          page: 1
        }
      });
    },
    async loadSinglePatient() {
      if (!this.$route.params.patientid) {
        return;
      }
      if (this.$route.name !== 'patientprofile') {
        return;
      }
      try {
        this.selectedRow.id = this.$route.params.patientid;
        await this.$nextTick();
        this.$refs.PatientsInfoSidePanel.show();
      } catch (err) {
        console.log(err);
        this.showAlert('A patient with this ID could not be found.');
      }
    },
    async setTab(tab) {
      this.tab = tab;
      this.search = '';
      const filter = this.patientOutreachFilters[tab] || {};
      this.$router.push({
        query: {
          page: undefined,
          tab,
          q: _isEmpty(filter) ? undefined : btoa(JSON.stringify(filter))
        }
      });
      await this.initPatientOutreachFilters();
      this.getStatusCount();
      this.alert.show = false;
    },
    showAlert(msg) {
      this.alert.show = true;
      this.alert.message = msg;
    },
    async getStatusCount() {
      await this.$store.dispatch('patients/getStatusCounts');
    },
    async viewInfo(row) {
      this.selectedRow = row;
      this.alert.show = false;
      this.showInfoViewModal = true;
      await this.$nextTick();
      this.$refs.PatientsInfoSidePanel.show();
    },
    showToast(message, variant = 'danger') {
      this.toast.message = message;
      this.toast.variant = variant;
      this.$bvToast.show('viewToast');
    },
    updateTime(id) {
      this.$refs[this.refList[this.tab]].updateTime(id);
    },
    clearSearch() {
      this.$refs.searchInput.focus();
      this.search = '';
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          search: undefined
        }
      });
    }
  }
};