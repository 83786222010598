import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueApollo from 'vue-apollo';

import '@bywinona/corefront/dist/style.css'

import { BootstrapVue, IconsPlugin, LayoutPlugin } from 'bootstrap-vue';
import '@bywinona/corefront/dist/style.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/assets/scss/theme.scss';
import './tailwind.css';
import '@bywinona/corefront/dist/style.css'

import VuePageTransition from 'vue-page-transition';
import VueResizeObserver from 'vue-resize-observer';
import lineClamp from 'vue-line-clamp';
import vSelect from 'vue-select';
import FloatingVue from 'floating-vue';

import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

import CoreConfirmationDialog from '@corefront/components-v2/CoreConfirmationDialog/CoreConfirmationDialog.js';

import './modules/vee-validate';

import VueSecureHTML from 'vue-html-secure';

Vue.use(VueSecureHTML);

Vue.use(LayoutPlugin, { breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] });

Vue.use(CoreConfirmationDialog)
Vue.use(PerfectScrollbar);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VuePageTransition);
Vue.use(require('vue-shortkey'));
Vue.use(VueResizeObserver);
Vue.use(lineClamp);
Vue.use(FloatingVue);

Vue.component('VIcon', () => import('./icon/icon.vue'));
Vue.component('VSelect', vSelect);

import VueMask from 'v-mask';
Vue.use(VueMask);

import 'floating-vue/dist/style.css';

Vue.config.productionTip = false;

Vue.use(VueApollo);

Vue.prototype.window = window;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
