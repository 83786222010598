var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("button", _vm._g({
    staticClass: "base-button tw-font-[400] tw-border tw-relative tw-overflow-hidden disabled:tw-cursor-not-allowed",
    class: [_vm.sizeClass, _vm.colorClass, _vm.disabledClass, _vm.roundedClass],
    attrs: {
      disabled: _vm.$attrs.disabled || _vm.loading
    }
  }, _vm.$listeners), [!_vm.outline && !_vm.text ? _c("div", {
    staticClass: "[ tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full ] [ tw-opacity-0 hover:tw-opacity-10 tw-bg-black ] tw-transition tw-flex tw-gap-1"
  }) : _vm._e(), _vm._t("default"), _vm.loading ? _c("IconLoading", {
    staticClass: "tw-absolute tw-top-[50%] tw-left-[10px] tw-translate-y-[-50%] tw-size-[18px]",
    class: _vm.loadingColorClass
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };