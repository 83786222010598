var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "p-4",
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": ""
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-alert", {
    attrs: {
      show: _vm.alert.show,
      variant: "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "6",
      offset: "4"
    }
  }, [_c("h2", {
    staticClass: "heading"
  }, [_vm._v(" Pause Prescription until... ")]), _c("b-row", [_c("b-col", [_c("p", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Patient")]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.rowData.firstname) + " " + _vm._s(_vm.rowData.lastname))])])])], 1), _c("b-row", [_c("b-col", [_c("p", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Prescription")]), _c("br"), _c("span", [_vm._v(_vm._s(_vm.rowData.medicinename) + " | " + _vm._s(_vm.rowData.dosage))])])])], 1), _c("b-form-radio", {
    attrs: {
      name: "pause-prescription",
      value: "Set a Date"
    },
    model: {
      value: _vm.selectedOption,
      callback: function ($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }, [_vm._v(" Pause Until ")]), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "12"
    }
  }, [_vm.selectedOption == "Set a Date" ? _c("b-form-datepicker", {
    staticClass: "mt-2 mb-4",
    attrs: {
      id: "pause-until-datepicker",
      min: _vm.tomorrow
    },
    model: {
      value: _vm.dateForResume,
      callback: function ($$v) {
        _vm.dateForResume = $$v;
      },
      expression: "dateForResume"
    }
  }) : _vm._e()], 1)], 1), _c("b-form-radio", {
    attrs: {
      name: "pause-prescription",
      value: "Indefinite"
    },
    model: {
      value: _vm.selectedOption,
      callback: function ($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }, [_vm._v(" Indefinitely ")])], 1)], 1), _c("b-row", {
    staticClass: "mt-4"
  }, [_c("b-col", {
    attrs: {
      offset: "4",
      cols: "6"
    }
  }, [_c("b-row", [_c("b-col", [_c("b-button", {
    attrs: {
      variant: "secondary",
      block: "",
      pill: "",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: _vm.pausePrescription
    }
  }, [_vm._v(" Continue ")]), _c("b-button", {
    attrs: {
      variant: "outline-dark",
      block: "",
      pill: "",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Cancel ")])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };