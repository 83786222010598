import { ChatService } from '@/services/chat.service.js';

export default {
  namespaced: true,
  state: {
    failedMessages: [],
  },
  getters: {
    getFailedMessagesByConversationId: state => conversationId => {
      return state.failedMessages
        .filter(msg => {
          return msg.payload.conversationId === conversationId
        })
        .map(x => x.payload)
    }
  },
  mutations: {
    SET_STATE (state, payload) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    }
  },
  actions: {
    async store (ctx, message) {
      await ChatService.storeFailedMessage(message);
    },
    async get ({ commit }, { conversationId, failedMessageType }) {
      const { data } = await ChatService.getFailedMessages({
        conversationId,
        type: failedMessageType
      });

      commit('SET_STATE', {
        failedMessages: data
      })

      return data
    },
    async resolve (ctx, messageId) {
      await ChatService.resolveFailedMessage(messageId);
    },
    async resolveByMessageId ({ state, commit }, messageId) {
      const failedMessage = state.failedMessages.find(msg => {
        return msg.payload.id === messageId
      })

      if (!failedMessage) {
        return
      }

      await ChatService.resolveFailedMessage(failedMessage.id);

      const index = state.failedMessages.findIndex(msg => {
        return msg.id === failedMessage.id
      });

      state.failedMessages.splice(index, 1);

      commit('SET_STATE', {
        failedMessages: state.failedMessages
      })
    },
  },
};
