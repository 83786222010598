import "core-js/modules/es.array.reduce.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.structured-clone.js";
// utils
import { querySelectorAsync } from '@corefront/utils/querySelectorAsync';
import { getElementDetails } from '@corefront/utils/getElementDetails';

// libs
import _sum from 'lodash/sum';
export default {
  props: {
    filter: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filterContentHeight: 0
    };
  },
  computed: {
    model: {
      get() {
        const {
          field,
          type
        } = this.filter;
        return this.value[field] || (['multiselect', 'select'].includes(type) ? [] : '');
      },
      set(value) {
        const filter = structuredClone(this.value);
        this.$set(filter, this.filter.field, value);
        this.$emit('input', filter);
      }
    }
  },
  async mounted() {
    this.initContentHeight();
  },
  methods: {
    async initContentHeight() {
      const [content, contentInner, footer] = await Promise.all([querySelectorAsync('#patients-side-panel-filter-content'), querySelectorAsync('#patients-side-panel-filter-content-inner'), querySelectorAsync('#patients-side-panel-filter-footer')]);
      const contentDetails = getElementDetails(content);
      const contentInnerDetails = getElementDetails(contentInner);
      const footerDetails = getElementDetails(footer);
      const contentChildrenHeight = Array.from(content.children).slice(0, -1).reduce((acc, child) => {
        const {
          rect,
          styles
        } = getElementDetails(child);
        return acc + rect.height + parseInt(styles.marginBottom) + parseInt(styles.marginTop);
      }, 0);
      const contentInnerChildrenHeight = Array.from(contentInner.children).slice(0, -1).reduce((acc, child) => {
        const {
          rect,
          styles
        } = getElementDetails(child);
        return acc + rect.height + parseInt(styles.marginBottom) + parseInt(styles.marginTop);
      }, 0);
      const sum = _sum([parseInt(contentDetails.styles.gap) * (content.children.length - 1), parseInt(contentInnerDetails.styles.gap) * (contentInner.children.length - 1), parseInt(contentDetails.styles.paddingTop), parseInt(contentDetails.styles.paddingBottom), contentChildrenHeight, contentInnerChildrenHeight, footerDetails.rect.height]);
      this.filterContentHeight = window.innerHeight - sum;
    },
    onClickSelectAll(el) {
      this.model = el.target.checked ? this.filter.options.map(option => option.value) : [];
    },
    onClearAll() {
      this.$refs['select-all-checkbox'].checked = false;
      this.model = [];
    }
  }
};