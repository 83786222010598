import { mapState } from 'vuex';
import { formatDistanceToNowStrict } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import { formatDistance } from '@/services/util.js';
import IconForward from '@/components/icon/IconForward.vue';
import IconCircle from '@/components/icon/IconCircle.vue';
import IconChat from '@/components/icon/IconChat.vue';
import CoreAvatar from '@corefront/components-v2/Core/CoreAvatar.vue';
import { ROLES } from '@corefront/constant/messages';

// utils
import { trimHtmlTags } from '@corefront/utils/trimHtmlTags';
export default {
  components: {
    IconForward,
    IconCircle,
    IconChat,
    CoreAvatar
  },
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    conversation: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    isAdmin() {
      return this.conversation.lastMessageSenderRole === ROLES.ADMIN || this.conversation.lastMessageSenderRole === ROLES.SUPER_ADMIN;
    },
    formatTimeValue() {
      var _this$conversation;
      if ((_this$conversation = this.conversation) !== null && _this$conversation !== void 0 && _this$conversation.lastMessageActivity) {
        return formatDistanceToNowStrict(new Date(this.conversation.lastMessageActivity * 1000), {
          addSuffix: true,
          locale: {
            ...locale,
            formatDistance
          }
        });
      } else {
        return '';
      }
    }
  },
  methods: {
    trimHtmlTags
  }
};