var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tw-flex tw-flex-col tw-gap-5",
    attrs: {
      id: "patients-side-panel-filter-content-inner"
    }
  }, [_c("div", {
    staticClass: "tw-flex tw-items-center tw-justify-between"
  }, [_c("div", {
    staticClass: "tw-flex tw-items-center tw-gap-1 tw-cursor-pointer",
    on: {
      click: function ($event) {
        return _vm.$emit("close");
      }
    }
  }, [_c("IconChevron"), _vm._v(" Back ")], 1), _c("div", {
    staticClass: "tw-text-cumin tw-cursor-pointer",
    on: {
      click: _vm.onClearAll
    }
  }, [_c("span", [_vm._v("Clear all")])])]), ["select", "multiselect"].includes(_vm.filter.type) ? [_c("label", {
    staticClass: "tw-bg-rosy-brown/20 tw-h-[33px] tw-px-2 tw-flex tw-items-center tw-gap-2 tw-rounded tw-mb-0 tw-cursor-pointer",
    attrs: {
      for: "PatientsSidePanelFilterContent-select-all"
    }
  }, [_c("span", {
    staticClass: "tw-select-none"
  }, [_vm._v("Select All")]), _c("input", {
    ref: "select-all-checkbox",
    attrs: {
      id: "PatientsSidePanelFilterContent-select-all",
      type: "checkbox"
    },
    on: {
      change: _vm.onClickSelectAll
    }
  })]), _c("ul", {
    staticClass: "tw-mb-0 tw-overflow-y-auto",
    style: {
      height: `${_vm.filterContentHeight}px`
    }
  }, _vm._l(_vm.filter.options, function (option) {
    return _c("li", {
      key: option.value,
      staticClass: "tw-flex tw-items-center tw-gap-2"
    }, [_c("input", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.model,
        expression: "model"
      }],
      attrs: {
        id: `PatientsSidePanelFilterContent-${option.value}`,
        type: "checkbox"
      },
      domProps: {
        value: option.value,
        checked: Array.isArray(_vm.model) ? _vm._i(_vm.model, option.value) > -1 : _vm.model
      },
      on: {
        change: function ($event) {
          var $$a = _vm.model,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = option.value,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.model = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.model = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.model = $$c;
          }
        }
      }
    }), _c("label", {
      staticClass: "tw-m-0 tw-cursor-pointer tw-select-none tw-w-full",
      attrs: {
        for: `PatientsSidePanelFilterContent-${option.value}`
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")])]);
  }), 0)] : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };