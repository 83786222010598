import "core-js/modules/es.array.push.js";
// socket
import socket from '@corefront/utils/socket';

// constants
import { EVENTS } from '@corefront/constant/socket';
import { TYPES, ROLES, CATEGORY, FAILED_MESSAGE_TYPE } from '@corefront/constant/messages';

// libs
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapGetters, mapActions } from 'vuex';

// mixins
import { chatThread } from '@corefront/mixins-v2/chatThread';
import { failedMessagesMixin } from '@/mixins/failedMessages';
import ChatCategoryColumn from '@/components/chat/ChatCategoryColumn.vue';
import ChatMessageListColumn from '@/components/chat/ChatMessageListColumn.vue';
import ChatMessagesColumn from '@/components/chat/ChatMessagesColumn.vue';
import ChatProfileDisplay from '@/components/chat/ChatProfileDisplay';
export default {
  components: {
    ChatCategoryColumn,
    ChatMessageListColumn,
    ChatMessagesColumn,
    ChatProfileDisplay
  },
  mixins: [chatThread, failedMessagesMixin],
  data() {
    return {
      isMessagesLoaded: false,
      onlineStatusInterval: null,
      onlineStatusResponse: null,
      chatMessageColumnKey: uuidv4(),
      isProcessing: false,
      sortedConversations: []
    };
  },
  computed: {
    ...mapState('chat', ['selectedConversation', 'conversations', 'showProfile', 'messages', 'category']),
    ...mapState('auth', ['currentUser']),
    ...mapGetters('failedMessages', ['getFailedMessagesByConversationId'])
  },
  watch: {
    selectedConversation() {
      this.chatMessageColumnKey = uuidv4();
    },
    conversations(val) {
      this.sortedConversations = val;
    },
    sortedConversations(val) {
      this.$nextTick(() => {
        this.$store.dispatch('chat/updateConversations', val);
      });
    }
  },
  async created() {
    this.$store.commit('chat/SET_STATE', {
      messages: [],
      selectedConversation: null
    });
    this.initSocketListeners();
    this.pollOnlineStatus();
    this.$_failedMessages_pollSaveFailedMessages();
    this.initConversations();
  },
  beforeDestroy() {
    clearTimeout(this.onlineStatusInterval);
  },
  methods: {
    ...mapActions('chat', ['getConversationById']),
    async initConversations() {
      try {
        var _conversation$assigne;
        this.isProcessing = true;
        const conversationId = this.$route.params.conversationId;
        if (!conversationId) {
          this.$store.commit('chat/SET_STATE', {
            category: CATEGORY.ALL
          });
          await this.$store.dispatch('chat/fetchConversations', {
            category: CATEGORY.ALL
          });
          return;
        }
        const conversation = await this.getConversationById(conversationId);
        if (!conversation) {
          this.isProcessing = false;
          this.$store.commit('chat/SET_STATE', {
            category: CATEGORY.ALL
          });
          return;
        }
        if (conversation.dismissed) {
          this.$store.commit('chat/SET_STATE', {
            category: CATEGORY.DISMISSED
          });
          await this.$store.dispatch('chat/fetchConversations', {
            category: CATEGORY.DISMISSED
          });
        }
        await this.$store.dispatch('chat/selectConversation', conversation);
        const isAssigned = (_conversation$assigne = conversation.assignee) === null || _conversation$assigne === void 0 ? void 0 : _conversation$assigne[this.currentUser.id];
        if (this.category !== CATEGORY.DISMISSED) {
          this.$store.commit('chat/SET_STATE', {
            category: isAssigned ? CATEGORY.ASSIGNED : CATEGORY.ALL
          });
          await this.onCategoryUpdate(this.category, true);
        }
        this.$store.commit('chat/APPEND_CONVERSATION', conversation);
        await this.$nextTick();
        this.$refs.chatMessageListColumn.selectedConversation = conversation.id;
        await this.onConversationSelect(conversation);
      } finally {
        this.isProcessing = false;
      }
    },
    async onConversationSelect(conversation) {
      this.$store.dispatch('failedMessages/get', {
        conversationId: conversation.id,
        failedMessageType: FAILED_MESSAGE_TYPE.ADMIN_CHAT
      });
      this.$router.push(`/chat/${conversation.patient.id}`);
      await this.$store.dispatch('chat/selectConversation', conversation);
      this.$store.commit('chat/SET_STATE', {
        messages: [],
        selectedPatient: conversation.patient.id
      });
      await this.fetchMessages(conversation.patient.id);
    },
    async fetchMessages(patientId) {
      this.isMessagesLoaded = false;
      const conversationId = this.selectedConversation.id;
      await this.$store.dispatch('chat/fetchMessages', {
        patientId
      });
      await this.$_failedMessages_loadAdminChatFailedMessages(conversationId);
      await this.$_chatThread_scrollToBottom();
      this.isMessagesLoaded = true;
    },
    async initSocketListeners() {
      await socket.waitUntilReady();
      socket.ctx.on(EVENTS.ADMIN_CHAT_MESSAGE, async payload => {
        var _this$selectedConvers;
        const isAuthUserSender = payload.sender.id === this.currentUser.id;
        if (isAuthUserSender) {
          return;
        }
        if (payload.conversationId === ((_this$selectedConvers = this.selectedConversation) === null || _this$selectedConvers === void 0 ? void 0 : _this$selectedConvers.id)) {
          this.$store.commit('chat/APPEND_MESSAGE', {
            type: payload.type,
            id: payload.id,
            data: {
              ...payload,
              ...(payload.senderRole === ROLES.PATIENT ? {
                patient: payload.sender
              } : {
                admin: payload.sender
              })
            }
          });
          this.$store.commit('chat/UPDATE_CONVERSATION_BY_ID', {
            id: payload.conversationId,
            sender: payload.sender,
            lastMessage: payload.message,
            lastMessageSenderRole: payload.senderRole,
            lastMessageActivity: payload.sentAt,
            lastMessageType: payload.type
          });
        } else {
          this.$store.dispatch('chat/fetchConversations', {
            category: this.category
          });
          this.$store.dispatch('chat/getMetrics');
        }
        await this.$_chatThread_scrollToBottom();
      });
      socket.ctx.on(EVENTS.PATIENT_NOTE_ADDED, async note => {
        const isAuthUserSender = note.authorId === this.currentUser.id;
        if (isAuthUserSender) {
          return;
        }
        if (note.patientId === this.selectedConversation.patient.id) {
          this.$store.commit('chat/APPEND_MESSAGE', {
            type: TYPES.NOTE,
            id: note.id,
            data: {
              ...note,
              type: TYPES.NOTE
            }
          });
          this.$store.commit('chat/UPDATE_CONVERSATION_BY_ID', {
            id: note.patientId,
            sender: {
              firstName: note.author.firstName
            },
            lastMessage: note.messageBody,
            lastMessageSenderRole: this.currentUser.roletype,
            lastMessageActivity: note.timestampInteraction,
            lastMessageType: TYPES.NOTE
          });
        }
        await this.$_chatThread_scrollToBottom();
      });
      socket.ctx.on(EVENTS.ADMIN_CHAT_ASSIGNMENT_CHANGED, data => {
        if (!data.from && this.category === CATEGORY.UNASSIGNED) {
          this.$store.commit('chat/REMOVE_CONVERSATION', data.patientId);
        }
      });
      socket.ctx.on(EVENTS.ADMIN_CHAT_CONVERSATION_DISMISSED, data => {
        this.$store.commit('chat/REMOVE_CONVERSATION', data.patientId);
        this.$store.dispatch('chat/getMetrics');
      });
      socket.ctx.on(EVENTS.ADMIN_CHAT_CONVERSATION_REOPENED, data => {
        const conversations = [...this.conversations];
        const index = conversations.findIndex(conv => conv.id === data.patientId);
        if (index !== -1) {
          conversations.splice(index, 1);
          this.$store.dispatch('chat/updateConversations', conversations);
          this.$store.dispatch('chat/getMetrics');
        }
      });
      socket.ctx.on(EVENTS.ADMIN_CHAT_CONVERSATION_READ, async data => {
        const conversation = this.conversations.find(conv => {
          return conv.id === data.conversationId;
        });
        const newSeenByActivity = {
          id: data.conversationId,
          messageId: data.messageId,
          timestamp: data.timestamp,
          type: data.type
        };
        const seenBy = [newSeenByActivity, ...(conversation.seenBy || [])];
        this.$store.commit('chat/UPDATE_CONVERSATION_BY_ID', {
          id: data.conversationId,
          seenBy
        });
        this.$store.commit('chat/UPDATE_SELECTED_CONVERSATION', {
          seenBy
        });
        await this.$_chatThread_scrollToBottom();
      });
      socket.ctx.on(EVENTS.ONLINE_STATUS, data => {
        this.onlineStatusResponse = data;
        this.$store.dispatch('chat/updateOnlineStatus', data);
      });
    },
    async pollOnlineStatus() {
      if (this.onlineStatusInterval) {
        clearInterval(this.onlineStatusInterval);
      }
      const callback = async () => {
        const patientIdList = this.conversations.map(c => c.patient.id);
        await this.$store.dispatch('chat/getOnlineStatus', {
          users: patientIdList
        });
      };
      callback();
      this.onlineStatusInterval = setInterval(callback, 10000);
    },
    async onCategoryUpdate(category, isPushConversationId) {
      if (category !== CATEGORY.DISMISSED && this.$route.params.conversationId) {
        this.selectedPatientFromQuery = null;
      }
      if (isPushConversationId) {
        this.$router.push(`/chat/${this.$route.params.conversationId}`);
      } else {
        this.$router.push('/chat');
      }
      this.$store.commit('chat/SET_STATE', {
        selectedConversation: null
      });
      if (this.showProfile) {
        this.$store.commit('chat/SET_STATE', {
          showProfile: !this.showProfile
        });
      }
      this.$refs.chatMessageListColumn.selectedConversation = '';
      await this.$store.dispatch('chat/fetchConversations', {
        category
      });
    }
  }
};