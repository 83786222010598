var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.DOCTOR_REASSIGNED && _vm.entry.type === _vm.ENTRY_TYPES.PATIENT_TRANSFER ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("div", {
          domProps: {
            innerHTML: _vm._s(_vm.getDoctorReassignedMessage(_vm.entry))
          }
        })];
      },
      proxy: true
    }], null, false, 1581665068)
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.event === _vm.EVENT_KEYS.TREATMENT_SHIPPED ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("div", {
          staticClass: "tw-flex tw-flex-col tw-gap-1"
        }, [_c("div", {
          staticClass: "tw-mb-2"
        }, [_c("span", {
          staticClass: "tw-text-muted"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.messageBody) + " ")])]), _c("div", [_c("span", {
          staticClass: "tw-text-sm tw-leading-none tw-items-center tw-block"
        }, [_vm._v(" Tracking Number: ")]), _c("span", {
          staticClass: "tw-text-eclipse"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.trackingNo) + " ")])]), _c("div", [_c("span", {
          staticClass: "tw-text-sm tw-leading-none tw-items-center tw-block"
        }, [_vm._v(" Delivery Address: ")]), _c("span", {
          staticClass: "tw-text-eclipse"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.deliveryAddress) + " ")])])])];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.type === _vm.ENTRY_TYPES.MESSAGE || _vm.entry.type === _vm.ENTRY_TYPES.ATTACHMENT ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-url": _vm.communicationRole === _vm.COMMUNICATION_ROLE.RECEIVER ? _vm.entry.data.patient.avatarUrl : null,
      "avatar-text": _vm.communicationRole === _vm.COMMUNICATION_ROLE.SENDER ? _vm.entry.data.sender.firstName.charAt(0) : null,
      message: _vm.entry.data.message,
      "sent-date-unix": _vm.entry.data.sentAt,
      attachments: _vm.entry.data.attachments,
      "send-status": _vm.entry.sentStatus,
      seen: _vm.isSeenByPatient,
      "seen-date-unix": _vm.patientLastSeenTimestamp,
      articles: _vm.getArticleData(_vm.entry.data.articles),
      "hide-name": ""
    }
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.NOTES ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.entry.data.noteBy.charAt(0),
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "message-type": _vm.CHAT_TYPE.NOTES,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.SMS ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.entry.data.eventName === _vm.EVENT_NAME.OUTGOING ? _vm.CHAT_TYPE.ADMIN : _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.ONBOARDING ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.FOLLOW_UP ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.EVENT ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.ORDERS && _vm.entry.data.eventName === _vm.EVENT_NAME.PAYMENT_FAILED ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("div", {
          staticClass: "tw-flex tw-flex-col tw-gap-2"
        }, [_c("div", [_c("span", {
          staticClass: "tw-text-muted tw-text-xs tw-mb-[-3px] tw-block"
        }, [_vm._v("Payment Failed:")]), _c("p", {
          staticClass: "tw-m-0 tw-text-black"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.paymentFailed) + " ")])]), _c("div", [_c("span", {
          staticClass: "tw-text-muted tw-text-xs tw-mb-[-3px] tw-block"
        }, [_vm._v("Stripe Decline Code:")]), _c("p", {
          staticClass: "tw-m-0 tw-text-black"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.stripeDeclineCode) + " ")])]), _c("div", [_c("span", {
          staticClass: "tw-text-muted tw-text-xs tw-mb-[-3px] tw-block"
        }, [_vm._v("Stripe Error:")]), _c("p", {
          staticClass: "tw-m-0 tw-text-black"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.stripeDesc) + " ")])]), _c("div", [_c("span", {
          staticClass: "tw-text-muted tw-text-xs tw-mb-[-3px] tw-block"
        }, [_vm._v("Next Step:")]), _c("p", {
          staticClass: "tw-m-0 tw-text-black"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.stripeNextStep) + " ")])])])];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.ORDERS ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.INTERCOM ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("p", {
          staticClass: "tw-text-dark cf-break-words",
          domProps: {
            innerHTML: _vm._s(_vm.entry.data.messageBody)
          }
        })];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.REFUND ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("p", [_vm.entry.data.issuedBy ? _c("span", {
          staticClass: "tw-font-bold"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.issuedBy))]) : _vm._e(), _vm._v(" refunded the following with a total amount of $" + _vm._s(_vm.entry.data.refundAmount || 0) + " ")]), _c("table", [_c("thead", [_c("tr", {
          staticClass: "tw-border-y tw-border-x-0 tw-border-solid tw-border-muted/10"
        }, [_c("th", {
          staticClass: "tw-px-2 tw-py-3 tw-break-words"
        }, [_vm._v(" Prescription Order No ")]), _c("th", {
          staticClass: "tw-px-2 tw-py-3"
        }, [_vm._v(" Medicine Name ")]), _c("th", {
          staticClass: "tw-px-2 tw-py-3"
        }, [_vm._v(" Refund Amount ")])])]), _c("tbody", [_c("tr", [_c("td", {
          staticClass: "tw-px-2 tw-py-3"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.prescription.prescriptionorderno) + " ")]), _c("td", {
          staticClass: "tw-px-2 tw-py-3"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.prescription.medicinename) + " ")]), _c("td", {
          staticClass: "tw-px-2 tw-py-3"
        }, [_vm._v(" $" + _vm._s(_vm.entry.data.refundAmount || 0) + " ")])])])])];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.SUPPORT_INTERACTION ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.entry.data.messageBody) + " "), _c("div", {
          staticClass: "tw-flex tw-gap-2"
        }, [_c("span", {
          staticClass: "text-muted"
        }, [_vm._v("Record URL:")]), _c("a", {
          attrs: {
            href: _vm.entry.data.transcriptionUrl
          }
        }, [_vm._v(" Listen here ")])])];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.JUST_CALL ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("div", [_c("p", {
          staticClass: "tw-text-xs tw-text-muted"
        }, [_vm._v(" Note By: ")]), _c("p", [_vm._v(_vm._s(_vm.entry.data.noteBy))])]), _c("div", [_c("p", {
          staticClass: "tw-text-xs tw-text-muted"
        }, [_vm._v(" Call Status: ")]), _c("p", {
          staticClass: "tw-capitalize"
        }, [_vm._v(" " + _vm._s(_vm.entry.data.callStatus) + " ")])]), _c("div", [_c("p", {
          staticClass: "tw-text-xs tw-text-muted"
        }, [_vm._v(" Note Content: ")]), _c("p", [_vm._v(_vm._s(_vm.entry.data.messageBody))])])];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.type === _vm.ENTRY_TYPES.JUST_CALL ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        var _vm$entry$data$fullJS, _vm$entry$data$fullJS2, _vm$entry$data$fullJS3, _vm$entry$data$fullJS4;
        return [_vm.entry.data.type === _vm.JUST_CALL_TYPES.CALL_COMPLETED ? [_c("div", {
          staticClass: "tw-flex tw-flex-col tw-gap-1"
        }, [_vm.entry.data.direction === _vm.JUST_CALL_DIRECTIONS.OUTGOING ? _c("div", [_c("span", {
          staticClass: "tw-text-sm tw-leading-none tw-items-center tw-block"
        }, [_vm._v(" Patient Contacted: ")]), _c("span", {
          staticClass: "tw-text-eclipse tw-font-bold"
        }, [_vm._v(" " + _vm._s(`${_vm.selectedConversation.patient.firstname} ${_vm.selectedConversation.patient.lastname} (${_vm.selectedConversation.patient.phoneno})`) + " ")])]) : _vm._e(), _c("div", [_c("span", {
          staticClass: "tw-text-sm tw-leading-none tw-block tw-items-center"
        }, [_vm._v(" Caller: ")]), _c("span", {
          staticClass: "tw-text-eclipse tw-font-bold"
        }, [_vm._v(_vm._s(_vm.entry.data.agentName))])]), _vm.entry.data.friendlyDuration ? _c("div", [_c("span", {
          staticClass: "tw-text-sm tw-leading-none tw-block tw-items-center"
        }, [_vm._v(" Call Duration: ")]), _c("span", {
          staticClass: "tw-text-eclipse tw-font-bold"
        }, [_vm._v(_vm._s(_vm.entry.data.friendlyDuration))])]) : _vm._e(), (_vm$entry$data$fullJS = _vm.entry.data.fullJSON) !== null && _vm$entry$data$fullJS !== void 0 && (_vm$entry$data$fullJS2 = _vm$entry$data$fullJS.callInfo) !== null && _vm$entry$data$fullJS2 !== void 0 && _vm$entry$data$fullJS2.recording ? _c("div", [_c("span", {
          staticClass: "tw-text-sm tw-leading-none tw-block tw-items-center"
        }, [_vm._v(" Recording: ")]), _c("span", {
          staticClass: "tw-text-eclipse tw-font-bold"
        }, [_c("a", {
          attrs: {
            href: _vm.entry.data.fullJSON.callInfo.recording,
            target: "_blank"
          }
        }, [_vm._v(" Listen here ")])])]) : _vm._e()])] : _vm._e(), _vm.entry.data.type === _vm.JUST_CALL_TYPES.SMS_SENT_RECEIVED ? [_c("div", {
          staticClass: "tw-flex tw-flex-col tw-gap-1"
        }, [_vm.entry.data.direction === _vm.JUST_CALL_DIRECTIONS.OUTGOING ? _c("div", [_c("span", {
          staticClass: "tw-text-sm tw-leading-none tw-block tw-items-center"
        }, [_vm._v(" Patient Contacted: ")]), _c("span", {
          staticClass: "tw-text-eclipse tw-font-bold"
        }, [_vm._v(" " + _vm._s(`${_vm.selectedConversation.patient.firstname} ${_vm.selectedConversation.patient.lastname} (${_vm.selectedConversation.patient.phoneno})`) + " ")])]) : _vm._e(), _c("div", [_c("span", {
          staticClass: "tw-text-sm tw-leading-none tw-block tw-items-center"
        }, [_vm._v(" Messenger: ")]), _c("span", {
          staticClass: "tw-text-eclipse tw-font-bold"
        }, [_vm._v(_vm._s(_vm.entry.data.agentName))])]), (_vm$entry$data$fullJS3 = _vm.entry.data.fullJSON) !== null && _vm$entry$data$fullJS3 !== void 0 && (_vm$entry$data$fullJS4 = _vm$entry$data$fullJS3.smsInfo) !== null && _vm$entry$data$fullJS4 !== void 0 && _vm$entry$data$fullJS4.body ? _c("div", [_c("span", {
          staticClass: "tw-text-sm tw-leading-none tw-block tw-items-center"
        }, [_vm._v(" Message: ")]), _c("span", {
          staticClass: "tw-text-eclipse tw-font-bold"
        }, [_vm._v(_vm._s(_vm.entry.data.fullJSON.smsInfo.body))])]) : _vm._e()])] : _vm._e()];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventCategory === _vm.EVENT_CATEGORY.PATIENT && _vm.entry.data.eventName === _vm.EVENT_NAME.PATIENT_CANCELLED ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_vm.entry.data.cancelledByRole === "ADMIN" ? [_vm._v(" Admin "), _c("strong", {
          staticClass: "tw-text-black"
        }, [_vm._v(_vm._s(_vm.entry.data.cancelledByName))]), _vm._v(" has cancelled patient "), _c("strong", {
          staticClass: "tw-text-black"
        }, [_vm._v(_vm._s(_vm.entry.data.patientName))]), _vm._v(". ")] : _vm.entry.data.cancelledByRole === "DOCTOR" ? [_c("strong", {
          staticClass: "tw-text-black"
        }, [_vm._v("Dr. " + _vm._s(_vm.entry.data.cancelledByName))]), _vm._v(" has cancelled patient "), _c("strong", {
          staticClass: "tw-text-black"
        }, [_vm._v(_vm._s(_vm.entry.data.patientName))]), _vm._v(". ")] : [_c("span", [_vm._v("Patient "), _c("strong", {
          staticClass: "tw-text-black"
        }, [_vm._v(_vm._s(_vm.entry.data.patientName))]), _vm._v(" has cancelled her Winona Subscription.")])]];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventName.includes("Allergy") ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      message: _vm.entry.data.messageBody,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("ul", _vm._l(_vm.parseMessage, function ({
          allergy,
          status,
          message
        }, allergyKey) {
          return _c("li", {
            key: allergyKey,
            staticClass: "tw-flex"
          }, [_c("p", {
            staticClass: "mb-1"
          }, [_vm._v(" " + _vm._s(allergy || message) + " "), status !== "changed" ? _c("span", [_vm._v(" has been " + _vm._s(status) + " ")]) : _vm._e()])]);
        }), 0)];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventName.includes("Medication Updated") ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      message: _vm.entry.data.messageBody,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("ul", _vm._l(_vm.parseMessage, function ({
          medication,
          status,
          message
        }, medicationKey) {
          return _c("li", {
            key: medicationKey,
            staticClass: "tw-flex"
          }, [_c("p", {
            staticClass: "mb-1"
          }, [_vm._v(" " + _vm._s(medication || message) + " "), status !== "changed" ? _c("span", [_vm._v(" has been " + _vm._s(status) + " ")]) : _vm._e()])]);
        }), 0)];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.eventName.includes("Hysterectomy") ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      message: _vm.entry.data.messageBody,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("p", {
          staticClass: "mb-1"
        }, [_vm._v(" Updated hysterectomy history to " + _vm._s(_vm.parseMessage.value ? "YES" : "NO") + " ")])];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.platform === "Calendly" ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": "Call Scheduled",
      "message-type": _vm.CHAT_TYPE.EVENT,
      message: _vm.entry.data.messageBody,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("p", [_vm._v("Agent: " + _vm._s(_vm.entry.data.event))]), _c("p", [_vm._v("Time: " + _vm._s(_vm.parseDate(`${_vm.entry.data.messageBody}`, "MMM dd, yyyy HH:mm aa (EEEE)")))]), _c("p", [_vm._v("Call Name: " + _vm._s(_vm.entry.data.eventCategory))]), _c("a", {
          attrs: {
            href: _vm.entry.data.eventName,
            target: "_blank"
          }
        }, [_vm._v("Scheduled URL Link")])];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : [_vm.EVENT_CATEGORY.ADMIN_UPDATE, _vm.EVENT_CATEGORY.INFORMATION_UPDATE].includes(_vm.entry.data.eventCategory) || _vm.entry.data.event === "Patient Migrated" ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      message: _vm.entry.data.messageBody,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c("p", [_vm._v(" " + _vm._s(_vm.entry.data.messageBody) + " ")]), _c("ul", _vm._l(_vm.entry.data.changedFrom, function (formValue, formKey) {
          return _c("li", {
            key: formKey,
            staticClass: "tw-flex tw-flex-items-center tw-gap-2"
          }, [_c("span", {
            staticClass: "tw-text-muted tw-text-sm tw-capitalize tw-flex-shrink-0"
          }, [_vm._v(" " + _vm._s(_vm.getFormLabelByKey(formKey)) + ":   ")]), _c("span", [formKey == "birthday" ? _c("span", [_vm._v(" " + _vm._s(_vm.formatBirthday(formValue)) + " ")]) : _c("span", [_vm._v(_vm._s(formValue))]), _c("b-icon", {
            staticClass: "tw-mx-1",
            attrs: {
              icon: "arrow-right"
            }
          }), formKey == "birthday" ? _c("b", [_vm._v(" " + _vm._s(_vm.formatBirthday(_vm.entry.data.changedTo[formKey])) + " ")]) : _c("b", [_vm._v(" " + _vm._s(_vm.entry.data.changedTo[formKey]) + " "), !_vm.entry.data.changedTo[formKey] ? _c("span", [_vm._v("(none)")]) : _vm._e()])], 1)]);
        }), 0)];
      },
      proxy: true
    }])
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm.entry.data.messageBody ? _c("ChatBubble", _vm._g(_vm._b({
    attrs: {
      "communication-role": _vm.communicationRole,
      "avatar-text": _vm.avatarIcon,
      message: _vm.entry.data.messageBody,
      "sent-date-unix": _vm.timestamp,
      "event-name": _vm.entry.data.eventName,
      "message-type": _vm.CHAT_TYPE.EVENT,
      "send-status": _vm.entry.sentStatus,
      "hide-name": ""
    }
  }, "ChatBubble", _vm.commonAttrs, false), _vm.listeners)) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };