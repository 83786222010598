import Vue from 'vue';
import { ChatService } from '@/services/chat.service.js';

import _castArray from 'lodash/castArray';

// constants
import { CATEGORY } from '@corefront/constant/messages'

export default {
  namespaced: true,
  state: {
    conversations: [],
    conversationsNextPageCursor: null,
    messages: [],
    filteredMessages: [],
    isFetchingConversations: false,
    nextPage: '',
    selectedConversation: null,
    conversationCount: {},
    showEvents: true,
    showProfile: false,
    selectedPatient: null,
    category: CATEGORY.ALL,
    assignee: null,
    macroDialog: false,
    messageTemplate: {},
    statesAPC: ['IA', 'MI', 'NH', 'NC', 'AZ'],
  },
  mutations: {
    SET_OPEN_MACRO (state, open) {
      state.macroDialog = open;
    },
    SET_ASSIGNEE (state, name) {
      state.assignee = name;
    },
    SET_CONVERSATIONS (state, conversations) {
      Vue.set(state, 'conversations', conversations);
    },
    SET_SELECTED_CONVERSATION (state, conversation) {
      state.selectedConversation = conversation;
    },
    SET_MESSAGES (state, messages) {
      state.messages = messages;
    },
    SET_NEXT_PAGE (state, nextPage) {
      state.nextPage = nextPage;
    },
    SET_CONVERSATION_ASSIGNMENT_STATUS (state, status) {
      state.selectedConversation.status = status;
    },
    SET_CONVERSATION_COUNT (state, category, count) {
      state.conversationCount[category] = count;
    },
    SET_FILTERED_MESSAGES (state, messages) {
      state.filteredMessages = messages;
    },
    SET_SHOW_EVENTS (state, show) {
      state.showEvents = show;
    },
    SET_SHOW_PROFILE (state, show) {
      state.showProfile = show;
    },
    SET_SELECTED_PATIENT (state, id) {
      state.selectedPatient = id;
    },
    SET_MESSAGE_TEMPLATE (state, msg) {
      state.messageTemplate = msg;
    },
    SET_STATE (state, payload) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    },
    APPEND_CONVERSATION (state, conversation) {
      const idx = state.conversations.findIndex(o => o.id == conversation.id);

      if (idx !== -1) {
        const existingConversation = state.conversations.splice(idx, 1)[0];
        state.conversations.unshift(existingConversation);
      } else {
        state.conversations.unshift(conversation);
      }
    },
    APPEND_CONVERSATION_BY_ID (state, conversationId) {
      const idx = state.conversations.findIndex(o => o.id == conversationId);
      const conversation = state.conversations[idx];

      if (idx !== -1) {
        const existingConversation = state.conversations.splice(idx, 1)[0];
        state.conversations.unshift(existingConversation);
      } else {
        state.conversations.unshift(conversation);
      }
    },
    REMOVE_CONVERSATION (state, conversationId) {
      const idx = state.conversations.findIndex(x => x.id === conversationId);

      if (idx === -1) {
        return
      }

      state.conversations.splice(idx, 1);
    },
    APPEND_MESSAGE (state, messages) {
      state.messages = [..._castArray(messages), ...state.messages];
    },
    DELETE_MESSAGE_BY_ID (state, messageId) {
      const messageIndex = state.messages.findIndex(m => m.id === messageId)

      if (messageIndex === -1) {
        return
      }

      state.messages.splice(messageIndex, 1)
    },
    UPDATE_CONVERSATION_BY_ID (state, { id, ...properties }) {
      const idx = state.conversations.findIndex(conversation => {
        return conversation.id === id;
      });

      if (idx == -1) {
        return;
      }

      for (const key in properties) {
        state.conversations[idx][key] = properties[key];
      }
    },
    UPDATE_SELECTED_CONVERSATION (state, properties) {
      for (const property in properties) {
        state.selectedConversation[property] = properties[property];
      }
    },
    UPDATE_MESSAGE_BY_ID (state, { id, ...properties }) {
      const idx = state.messages.findIndex(message => {
        return message.id === id;
      });

      if (idx == -1) {
        return;
      }

      for (const key in properties) {
        state.messages[idx][key] = properties[key];
      }
    },
  },
  getters: {
    conversationPatientName (state) {
      const selectedConversation = state.selectedConversation;

      return selectedConversation
        ? `${selectedConversation.patient.firstName} ${selectedConversation.patient.lastName}`
        : '';
    },
    getConversations (state) {
      return state.conversations;
    },
    getSelectedConversation (state) {
      return state.selectedConversation;
    },
    getMessages (state) {
      return state.messages;
    },
    getNextPage (state) {
      return state.nextPage;
    },
    getFilteredMessages (state) {
      return state.filteredMessages;
    },
    getShowEvents (state) {
      return state.showEvents;
    },
    getShowProfile (state) {
      return state.showProfile;
    },
    getSelectedPatient (state) {
      return state.selectedPatient;
    },
    getAssignee (state) {
      return state.assignee;
    },
    selectedConversationId (state) {
      return state.selectedConversation ? state.selectedConversation.id : null;
    }
  },
  actions: {
    updateMessageById ({ state, commit }, { id, properties }) {
      const messages = [...state.messages];
      const index = messages.findIndex(msg => msg.id === id);

      if (index !== -1) {
        messages[index] = {
          ...messages[index],
          ...properties,
        };
      }

      commit('SET_MESSAGES', messages);
    },
    async getConversationById (ctx, conversationId) {
      const { data } = await ChatService.getSingleAdminPatientConversations(
        conversationId
      );

      return data;
    },
    setAssigneeManual ({ commit }, name) {
      commit('SET_ASSIGNEE', name);
    },
    async refreshAssignee ({ commit, state }, payload) {
      const conversation = state.selectedConversation || payload

      const { data } = await ChatService.getAssignedAdmin(
        conversation.patient.id
      );
      if (data.assignee) {
        commit(
          'SET_ASSIGNEE',
          `${data.assignee.firstName} ${data.assignee.lastName}`
        );
      } else {
        commit('SET_ASSIGNEE', null);
      }
    },
    moveChatToTop ({ state }, conversationid) {
      const conversationindex = state.conversations.findIndex(
        o => o.id == conversationid
      );
      state.conversations.unshift(
        state.conversations.splice(conversationindex, 1)[0]
      );
    },
    async toggleEvents ({ commit, state }) {
      commit('SET_SHOW_EVENTS', !state.showEvents);
    },
    async getMetrics ({ state }) {
      const { data } = await ChatService.getMetrics();

      state.conversationCount = data;
    },
    async fetchConversations ({ commit, state }, { category, nextPage }) {
      try {
        commit('SET_STATE', {
          isFetchingConversations: true,
          conversations: nextPage ? [...state.conversations] : [],
        });

        const { data } = category === CATEGORY.DISMISSED
          ? await ChatService.getAdminPatientDismissedConversations({
            type: CATEGORY.DISMISSED.toLowerCase(),
            cursor: nextPage ? state.conversationsNextPageCursor : null
          })
          : await ChatService.getAdminPatientConversations({
            type: category === CATEGORY.ALL ? null : category,
            cursor: nextPage ? state.conversationsNextPageCursor : null
          });

        commit('SET_STATE', {
          conversations: nextPage ? [...state.conversations, ...data.items] : data.items,
          conversationsNextPageCursor: data.nextPageCursor,
        });

        return data;
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        commit('SET_STATE', {
          isFetchingConversations: false,
        });
      }
    },
    async getOnlineStatus ({ commit, state }, payload) {
      const { data: patientOnlineList } = await ChatService.getOnlineStatus(
        payload
      );

      const conversations = state.conversations.map(conversation => {
        const correspondingUser = patientOnlineList.find(
          patient => patient.userId === conversation.patient.id
        );

        if (correspondingUser) {
          conversation.isOnline = correspondingUser.online;
        }

        return conversation;
      });

      commit('SET_CONVERSATIONS', conversations);
    },
    updateOnlineStatus ({ commit, state }, { userId, status }) {
      const conversations = state.conversations.map(conversation => {
        if (conversation.patient.id === userId) {
          conversation.isOnline = status === 'online';
        }

        return conversation;
      });

      commit('SET_CONVERSATIONS', conversations);
    },
    async selectConversation ({ commit }, conversation) {
      const { data } = await ChatService.getConversationAssignment(
        conversation.id
      );

      conversation.status = data.status;
      conversation.assignee = data.assignee;

      if (data.assignee) {
        const assigneeObj = data.assignee[Object.keys(data.assignee)[0]];
        const name = `${assigneeObj.firstName} ${assigneeObj.lastName}`;
        commit('SET_ASSIGNEE', name);
      } else {
        commit('SET_ASSIGNEE', null);
      }

      commit('SET_SELECTED_CONVERSATION', conversation);

      return conversation;
    },
    // Override conversation patient for nonexisting conversations
    async selectConversationPatient ({ commit }, patient) {
      commit('SET_SELECTED_CONVERSATION', {
        patient,
      });
    },
    async fetchMessages ({ commit, state }, { patientId, nextPage }) {
      const { data: messages } = await ChatService.getAdminPatientMessages(
        patientId,
        nextPage
      );

      commit('SET_MESSAGES', [...state.messages, ...messages.data]);
      commit('SET_NEXT_PAGE', messages.nextPageCursor || null);

      return messages.nextPageCursor;
    },
    updateConversations ({ commit }, conversations) {
      commit('SET_CONVERSATIONS', conversations);
    },
    async assignConversation ({ commit, dispatch, state }, payload) {
      const res = payload.assign
        ? await ChatService.assignConversation(payload.conversationid)
        : await ChatService.unassignConversation(payload.conversationid)

      dispatch('getMetrics');

      if (state.selectedConversation) {
        commit('SET_CONVERSATION_ASSIGNMENT_STATUS', payload.assign);
      }

      return res;
    },
    async dismissConversation ({ state }, { patientId, conversationId }) {
      const { data } = await ChatService.dismissPatientConversation(patientId);
      if (data) {
        state.conversations = state.conversations.filter(
          o => o.id != conversationId
        );
      }
    },
    async reopenConversation (_, { patientId }) {
      const { data } = await ChatService.reopenPatientConversation(patientId);

      return data;
    },
  },
};
