import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class ChatService extends BaseService {
  static async listConversation (nextpage) {
    try {
      const response = await this.request({ auth: true, v3: true })
        .get('/v1/conversations', {
          params: {
            nextpage,
            limit: 50
          }
        })

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async listSingleConversation (patientid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversationv2/patient/${patientid}/admin`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchConversation (searchQuery) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversationv2/search?contains=${searchQuery}`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getConversation (conversationId) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversation/${conversationId}`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getConversationInfo (conversationId) {
    try {
      const response = await this.request({ auth: true, v3: true }).get(
        `/v1/conversation/${conversationId}`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async listConversationMember (conversationId) {
    try {
      const response = await this.request({ auth: true, v3: true }).get(
        `/v1/conversation/${conversationId}/members`,
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async listMessage (conversationId, nextpage) {
    try {
      if (process.env.VUE_APP_MESSAGING_VERSION === 'v2') {
        const response = await this.request({ auth: true, v3: true }).get(
          `/v1/conversation/${conversationId}/messages${
            nextpage ? `?cursor=${nextpage}` : ''
          }`
        );

        return new ResponseWrapper(response, response.data);
      } else {
        const response = await this.request({ auth: true }).get(
          `/conversation/${conversationId}/message?nextpage=${nextpage}`
        );

        return new ResponseWrapper(response, response.data.body);
      }
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async sendMessage (data) {
    try {
      const response = await this.request({ auth: true }).post(
        `/conversation/${data.id}/message`,
        {
          message: data.message,
        }
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async sendMessageWithAttachment (conversationid, data, config = {}) {
    try {
      const response = await this.request({ auth: true }).post(
        `/conversation/${conversationid}/messageattachment`,
        data,
        config
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async ignoreMessage (conversationid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/conversation/${conversationid}/ignore`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async snoozeMessage (conversationid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/conversation/${conversationid}/snooze`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async needPatientReply (conversationid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/conversation/${conversationid}/needspatientreply`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async markAsRead (conversationid, messageid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/conversation/${conversationid}/message/${messageid}/read`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async markAsUnread (conversationid) {
    try {
      const response = await this.request({ auth: true }).put(
        `/conversation/${conversationid}/unread`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async markConversationAsRead (conversationid) {
    try {
      const response = await this.request({ auth: true, v3: true }).patch(
        `/v1/conversation/${conversationid}/mark-as-read`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getUnreadMessageCount (userid) {
    try {
      const response = await this.request({ auth: true, v2: true }).get(
        `/conversation/all/unreadmessagecount/${userid}`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async forwardRequest (conversationid, payload) {
    try {
      const response = await this.request({ auth: true }).post(
        `/conversation/${conversationid}/forwardmessage`,
        payload
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getConversationForwardRequests (conversationid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversation/${conversationid}/forwardmessage`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getSingleForwardRequest (conversationid, forwardedmessageid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/conversation/${conversationid}/forwardmessage/${forwardedmessageid}`
      );

      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  // Admin-Patient Chat
  static async getAdminPatientConversations (params) {
    try {
      const response = await this
        .request({ auth: true, v3: true })
        .get('/admin-patient-chat/conversations', { params });

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getAdminPatientDismissedConversations ({ type = null, ...params }) {
    try {
      const response = await this
        .request({ auth: true, v3: true })
        .get(`/admin-patient-chat/conversations/${type}`, {
          params
        });

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getSingleAdminPatientConversations (patientId) {
    try {
      const response = await this.request({ auth: true, v3: true }).get(
        `/admin-patient-chat/conversations/${patientId}`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      return error;
    }
  }

  static async getAdminPatientMessages (patientId, nextPage) {
    try {
      const response = await this.request({ auth: true, v3: true }).get(
        `/admin/patients/${patientId}/messages${
          nextPage ? `?cursor=${nextPage}` : ''
        }`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async assignConversation (conversationId) {
    try {
      const response = await this.request({ auth: true, v3: true }).post(
        `/admin-patient-chat/conversations/${conversationId}/assign`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async unassignConversation (conversationId) {
    try {
      const response = await this.request({ auth: true, v3: true }).delete(
        `/admin-patient-chat/conversations/${conversationId}/assign`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getConversationAssignment (id) {
    try {
      const response = await this.request({ auth: true, v3: true }).get(
        `/admin-patient-chat/conversations/${id}/assignment-status`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async dismissPatientConversation (patientId) {
    try {
      const response = await this.request({ auth: true, v3: true }).patch(
        `/admin-patient-chat/conversations/${patientId}/dismiss`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async reopenPatientConversation (patientId) {
    try {
      const response = await this.request({ auth: true, v3: true }).post(
        `/admin-patient-chat/conversations/${patientId}/re-open`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getMetrics () {
    try {
      const response = await this.request({ auth: true, v3: true }).get(
        '/admin-patient-chat/conversations/metrics'
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getOnlineStatus (payload) {
    try {
      const response = await this.request({ auth: true, v3: true }).post(
        '/user/online_status',
        payload
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getAssignedAdmin (patientid) {
    try {
      const response = await this.request({ auth: true, v3: true }).get(
        `admin-patient-chat/conversations/${patientid}`
      );

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async storeFailedMessage (data) {
    try {
      const response = await this
        .request({ auth: true, v3: true })
        .post('failed-messages', data, {
          headers: {
            'x-request-id': data.payload.id
          }
        });

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getFailedMessages (query) {
    try {
      const response = await this.request({ auth: true, v3: true })
        .get('failed-messages', {
          params: query
        });

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async resolveFailedMessage (messageId) {
    try {
      const response = await this.request({ auth: true, v3: true })
        .patch(`failed-messages/${messageId}`, {
          status: 1
        });

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
