import { ArticleService } from '@/services/article.service';

export default {
  namespaced: true,
  state: {
    articles: [],
    // List of articles to display - Will only clear on refresh
    articlesDisplay: [],
    admins: [],
    articleDialog: false,
    insertMode: 'chat'
    // or 'macro'
  },
  mutations: {
    SET_INSERT_MODE (state, mode) {
      state.insertMode = mode;
    },
    SET_OPEN_ARTICLE (state, open) {
      state.articleDialog = open;
    },
    SET_ARTICLES (state, articles) {
      state.articles = articles;
    },
    SET_ARTICLES_DISPLAY (state, articles) {
      const stateArticleIds = state.articlesDisplay.map(a => a.id);
      
      articles.forEach(article => {
        if (!stateArticleIds.includes(article.id)) {
          state.articlesDisplay.push(article);
        }
      });
    },
    SET_ADMINS (state, admins) {
      state.admins = admins;
    },
  },
  actions: {
    async fetchArticles ({ state, commit, dispatch }, params = {}) {
      await dispatch('syncArticles');
      let articles = await ArticleService.getArticles(params);
      const queriedArticles = articles;

      if (params.offset && params.offset > 0) {
        articles = [...state.articles, ...articles.filter(article => article.title.length > 0)];
      } else {
        articles = articles.filter(article => article.title.length > 0);
      }
      commit('SET_ARTICLES', articles);
      commit('SET_ARTICLES_DISPLAY', articles);
      
      return queriedArticles;
    },
    async searchArticles ({}, query) {
      const articles = await ArticleService.getArticles({ q: query });
      
      return articles;
    },
    async fetchArticleAdmins ({ commit, dispatch }) {
      await dispatch('syncAdmins');
      const admins = await ArticleService.getArticleAdmins();
      commit('SET_ADMINS', admins);
    },
    async syncArticles () {
      await ArticleService.syncArticles();
    },
    async syncAdmins () {
      await ArticleService.syncAdmins();
    },
    async fetchArticlesById ({ commit, state }, { ids }) {
      try {
        const articleIds = state.articles.map(a => a.id);
        const newIds = ids.filter(id => !articleIds.includes(id));
        
        if (!newIds.length || newIds.length === 0) {
          return state.articles.filter(a => ids.includes(a.id));
        }
        
        const { data } = await ArticleService.getArticlesById(newIds);
        
        commit('SET_ARTICLES_DISPLAY', [...state.articles, ...data]);
        commit('SET_ARTICLES', [...state.articles, ...data]);
      } catch (error) {
        console.error(error);
        
        return error;
      } 
    },
  },
  getters: {
    getAdmin: state => id => {
      return state.admins.find(admin => admin.id === id);
    }
  }
}