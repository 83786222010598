/**
import { failedMessagesMixin } from "@/mixins/failedMessages";
 */

// libs
import Vue from 'vue'
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import _flow from 'lodash/flow'
import _orderBy from 'lodash/orderBy'
import _castArray from 'lodash/castArray'

// utils
import { prefixMethods } from '@/utils/prefixMethods'
import { now } from '@corefront/utils/now';
import { sleep } from '@corefront/utils/sleep';
import { fileToDataUrl, base64toFile } from '@corefront/utils/file';
import socket from '@corefront/utils/socket'

// constants
import { TYPES, MESSAGE_SEND_STATUS, FAILED_MESSAGE_TYPE } from '@corefront/constant/messages'
import { EVENTS } from '@corefront/constant/socket'
const PREFIX = '$_failedMessages_';

// services
import { ActionService } from '@/services/action.service';

// mixins
import { chatThread } from '@corefront/mixins-v2/chatThread';

const data = Vue.observable({
  isStoringFailedMessages: false,
})

const TIMEOUT = 15
const FAILED_MESSAGES_KEY = {
  ADMIN_CHAT: userId => `failed-messages:admin-patient-chat-messages-${userId}`,
}

function transformFilesForEntry (files) {
  if (!files) {
    return []
  }

  return files.map(file => {
    return {
      name: file.name,
      size: file.size,
      type: file.type,
      file,
    }
  });
}

function createAdminMessageEntry (message) {
  const messageClone = structuredClone(message)

  if (messageClone.files && messageClone.files.length) {
    messageClone.attachment = transformFilesForEntry(messageClone.files)

    delete messageClone.files
  }

  return {
    id: messageClone.id,
    userid: this.currentUser.id,
    message: messageClone.message,
    conversationId: messageClone.conversationId,
    sentat: messageClone.sentat || now(),
    attachment: messageClone.attachment || [],
    type: 'text',
    sentStatus: MESSAGE_SEND_STATUS.PENDING
  }
}

function createAdminChatEntry (message) {
  const messageClone = structuredClone(message)

  const attachments = messageClone.files?.length
    ? transformFilesForEntry(messageClone.files) || []
    : messageClone.attachments

  delete messageClone.files

  return {
    id: messageClone.id,
    type: TYPES.MESSAGE,
    cursor: null,
    data: {
      ...messageClone,
      sentAt: messageClone.sentAt || now(),
      attachments
    },
    sentStatus: MESSAGE_SEND_STATUS.PENDING
  }
}

export const failedMessagesMixin = {
  mixins: [chatThread],

  computed: {
    ...mapState('chat', {
      chatMessages: 'messages',
    }),
    ...mapState('message', {
      messages: 'messages',
    }),
    ...mapState('auth', ['currentUser']),
  },

  methods: prefixMethods({
    getLocalFailedMessages () {
      const key = FAILED_MESSAGES_KEY.ADMIN_CHAT(this.currentUser.id)

      return JSON.parse(localStorage.getItem(key) || '[]')
    },

    setLocalFailedMessages (messages) {
      const key = FAILED_MESSAGES_KEY.ADMIN_CHAT(this.currentUser.id)

      localStorage.setItem(key, JSON.stringify(messages))
    },

    deleteLocalFailedMessage (id) {
      const failedMessages = this.$_failedMessages_getLocalFailedMessages()

      const filteredFailedMessages = failedMessages.filter(failedMessage => {
        return failedMessage.message.id !== id
      })

      this.$_failedMessages_setLocalFailedMessages(filteredFailedMessages)
    },

    async pollSaveFailedMessages () {
      const callback = async () => {
        if (!navigator.onLine) {
          return
        }

        try {
          data.isStoringFailedMessages = true

          const failedMessages = this.$_failedMessages_getLocalFailedMessages()

          const promises = failedMessages.map(async failedMessage => {
            try {
              const { message, failedMessageType } = failedMessage

              await this.$store.dispatch('failedMessages/store', {
                type: failedMessageType,
                payload: message
              })

              switch (failedMessageType) {
                case FAILED_MESSAGE_TYPE.ADMIN_MESSAGE:
                  this.$store.commit('message/UPDATE_CONVERSATION_BY_ID', {
                    id: message.conversationId,
                    hasfailedmessage: true
                  });
                  break;

                case FAILED_MESSAGE_TYPE.ADMIN_CHAT:
                  this.$store.commit('chat/UPDATE_CONVERSATION_BY_ID', {
                    id: message.conversationId,
                    hasFailedMessage: true
                  });
                  break;

                default:
                  break;
              }
            } catch (error) {
              return failedMessage
            }
          })

          const filteredFailedMessages = await Promise.all(promises)

          this.$_failedMessages_setLocalFailedMessages(filteredFailedMessages.filter(x => !!x))
        } finally {
          data.isStoringFailedMessages = false
        }
      }

      // eslint-disable-next-line no-constant-condition
      while (true) {
        await callback()
        await sleep(1000)
      }
    },

    async onSendFail ({ failedMessageType, message }) {
      const failedMessages = this.$_failedMessages_getLocalFailedMessages()

      const messageExists = failedMessages.find(failedMessage => {
        return failedMessage.message.id === message.id
      })

      if (messageExists) {
        return
      }

      const files = [
        ..._castArray(message.attachments || []),
        ..._castArray(message.attachment || []),
      ]

      const base64Files = await Promise.all(
        files?.map(async file => {
          return {
            name: file.name,
            base64: await fileToDataUrl(file.file || file)
          }
        }) ?? []
      )

      failedMessages.push({
        failedMessageType,
        message: {
          ...message,
          base64Files,
          timestampFailed: now()
        }
      })

      this.$_failedMessages_setLocalFailedMessages(failedMessages)
    },

    async resolveFailedMessage ({ messageId, conversationId, failedMessageType }) {
      while (data.isStoringFailedMessages) {
        await sleep(100)
      }

      await this.$store.dispatch('failedMessages/get', {
        conversationId,
        failedMessageType
      });

      await this.$store.dispatch('failedMessages/resolveByMessageId', messageId);

      const failedMessages = this.$_failedMessages_getLocalFailedMessages()

      const newFailedMessages = failedMessages.filter(failedMessage => {
        return failedMessage.message.id !== messageId
      })

      this.$_failedMessages_setLocalFailedMessages(newFailedMessages)
    },

    async retrySendMessage ({
      message,
      commitUpdateMessage,
    }) {
      for (let i = 0; i <= 2; i++) {
        commitUpdateMessage({
          id: message.id,
          sentStatus: MESSAGE_SEND_STATUS.PENDING
        })

        /**
         * Dramatic effect for retry so user can see the message is being resent
         */
        await sleep(1000)

        try {
          if (!navigator.onLine) {
            commitUpdateMessage({
              id: message.id,
              sentStatus: MESSAGE_SEND_STATUS.FAILED
            })

            await sleep(1000)

            continue
          }

          await this.$_failedMessages_sendMessage({
            ...arguments[0],
            isRetry: true
          })

          return true
        } catch (error) {
          commitUpdateMessage({
            id: message.id,
            sentStatus: MESSAGE_SEND_STATUS.FAILED
          })
        }
      }

      commitUpdateMessage({
        id: message.id,
        sentStatus: MESSAGE_SEND_STATUS.FAILED
      })
    },

    async sendMessage ({
      message,
      isRetry = false,
      sendEvent,
      failedMessageType,
      commitDeleteMessage,
      commitAppendEntryToThread,
      commitUpdateMessage,
      commitUpdateConversation,
      messageTimestampKey,
      sendMessageFn
    }) {
      const messageClone = structuredClone(message)

      try {
        if (isRetry) {
          commitDeleteMessage()
        }

        commitAppendEntryToThread()

        commitUpdateMessage({
          id: messageClone.id,
          sentStatus: MESSAGE_SEND_STATUS.PENDING
        })

        this.$_chatThread_scrollToBottom();

        if (messageTimestampKey) {
          messageClone[messageTimestampKey] = now()
        }

        if (typeof sendMessageFn === 'function') {
          await sendMessageFn(message)
        } else {
          await socket.ctx
            .timeout(TIMEOUT * 1000)
            .emitWithAck(sendEvent, {
              ...messageClone,
              requestId: messageClone.id,
              id: isRetry ? uuidv4() : messageClone.id
            })
        }

        if (isRetry) {
          await this.$_failedMessages_resolveFailedMessage({
            conversationId: messageClone.conversationId || messageClone.conversationid,
            messageId: message.id,
            failedMessageType
          })
        }

        commitUpdateMessage({
          id: messageClone.id,
          sentStatus: MESSAGE_SEND_STATUS.SENT
        })

        commitUpdateConversation({
          hasSucceeded: true
        })
      } catch (error) {
        commitUpdateMessage({
          id: messageClone.id,
          sentStatus: MESSAGE_SEND_STATUS.FAILED
        })

        commitUpdateConversation({
          hasSucceeded: false
        })

        this.$_chatThread_scrollToBottom();

        if (!isRetry) {
          this.$_failedMessages_onSendFail({
            failedMessageType,
            message: messageClone
          })

          await this.$_failedMessages_retrySendMessage({
            ...arguments[0]
          })
        }

        throw error;
      }
    },

    async sendAdminPatientChatMessage ({ message, isRetry = false }) {
      const entry = createAdminChatEntry(message)

      delete message.files

      message.attachments = entry.data.attachments

      await this.$_failedMessages_sendMessage({
        message,
        messageTimestampKey: 'sentAt',
        isRetry,
        sendEvent: EVENTS.ADMIN_CHAT_NEW_MESSAGE,
        failedMessageType: FAILED_MESSAGE_TYPE.ADMIN_CHAT,
        commitDeleteMessage: () => {
          this.$store.commit('chat/DELETE_MESSAGE_BY_ID', message.id)
        },
        commitAppendEntryToThread: () => {
          this.$store.commit('chat/APPEND_MESSAGE', entry);
        },
        commitUpdateMessage: properties => {
          this.$store.commit('chat/UPDATE_MESSAGE_BY_ID', properties)
        },
        commitUpdateConversation: ({ hasSucceeded }) => {
          this.$store.commit(
            'chat/UPDATE_CONVERSATION_BY_ID',
            hasSucceeded
              ? {
                id: message.conversationId,
                sender: message.sender,
                lastMessage: message.message,
                lastMessageSenderRole: message.senderRole,
                lastMessageActivity: message.sentAt,
                lastMessageType: message.type,
              }
              : {
                id: message.conversationId,
                hasFailedMessage: true
              }
          );

          if (hasSucceeded) {
            this.$store.commit('chat/APPEND_CONVERSATION_BY_ID', message.conversationId);

            const messageListColumn = document.getElementById('messageListColumn');

            messageListColumn?.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        }
      })
    },

    async sendAdminPatientChatNote ({ message, isRetry = false }) {
      const entry = {
        id: message.id,
        type: TYPES.NOTE,
        data: message,
        sentStatus: MESSAGE_SEND_STATUS.PENDING
      }

      await this.$_failedMessages_sendMessage({
        message,
        messageTimestampKey: 'sentAt',
        isRetry,
        failedMessageType: FAILED_MESSAGE_TYPE.ADMIN_CHAT,
        async sendMessageFn () {
          await ActionService.addActionNote(message.patientId, {
            actionid: 'ac-all',
            notes: message.message,
            action: 'Other',
            source: 'APC',
          });
        },
        commitDeleteMessage: () => {
          this.$store.commit('chat/DELETE_MESSAGE_BY_ID', message.id)
        },
        commitAppendEntryToThread: () => {
          this.$store.commit('chat/APPEND_MESSAGE', entry)
        },
        commitUpdateMessage: properties => {
          this.$store.commit('chat/UPDATE_MESSAGE_BY_ID', properties)
        },
        commitUpdateConversation: ({ hasSucceeded }) => {
          this.$store.commit(
            'chat/UPDATE_CONVERSATION_BY_ID',
            hasSucceeded
              ? {
                id: message.conversationId,
                sender: message.sender,
                lastMessage: message.message,
                lastMessageSenderRole: message.senderRole,
                lastMessageActivity: message.sentAt,
                lastMessageType: message.type,
              }
              : {
                id: message.conversationId,
                hasFailedMessage: true
              }
          );
        }
      })
    },

    async sendAdminMessage ({ message, isRetry = false }) {
      const entry = createAdminMessageEntry.call(this, message)

      delete message.files

      message.attachment = entry.attachment

      await this.$_failedMessages_sendMessage({
        message,
        isRetry,
        sendEvent: EVENTS.ADMIN_MESSAGE_NEW_MESSAGE,
        failedMessageType: FAILED_MESSAGE_TYPE.ADMIN_MESSAGE,
        commitDeleteMessage: () => {
          this.$store.commit('message/REMOVE_MESSAGE_BY_ID', message.id)
        },
        commitAppendEntryToThread: () => {
          this.$store.commit('message/APPEND_MESSAGE', entry);
        },
        commitUpdateMessage: properties => {
          this.$store.commit('message/UPDATE_MESSAGE_BY_ID', properties)
        },
        commitUpdateConversation: response => {
          if (response.hasSucceeded) {
            this.$store.commit('message/UPDATE_CONVERSATION_BY_ID', {
              id: message.conversationid || message.conversationId,
              lastmessage: message.message,
              unreadcount: false,
            });
          } else {
            this.$store.commit('message/UPDATE_CONVERSATION_BY_ID', {
              id: message.conversationid || message.conversationId,
              hasfailedmessage: true
            });
          }
        }
      })
    },

    async loadAdminMessageFailedMessages (conversationId) {
      const messages = this.$store.state.message.messages
      const messageIds = new Set(messages.map(m => m.id))

      const failedMessages = await this.$store.dispatch('failedMessages/get', {
        conversationId,
        failedMessageType: FAILED_MESSAGE_TYPE.ADMIN_MESSAGE
      })

      const entries = _flow([
        fMsgs => {
          return fMsgs.filter(message => !messageIds.has(message.payload.id))
        },
        fMsgs => {
          return _orderBy(fMsgs, ['payload.timestampFailed'], ['asc'])
        },
        fMsgs => {
          return fMsgs.map((message, idx) => {
            const filesFromBase64 = message.payload.base64Files?.map(file => {
              return base64toFile(file.base64, {
                name: file.name
              })
            }) ?? []

            return {
              ...createAdminMessageEntry.call(this, {
                ...message.payload,
                files: filesFromBase64,
              }),
              sentat: now() - (fMsgs.length - idx),
              sentStatus: MESSAGE_SEND_STATUS.FAILED
            }
          })
        }
      ])(failedMessages)

      failedMessages
        .filter(x => messageIds.has(x.payload.id))
        .forEach(failedMessage => {
          this.$store.dispatch('failedMessages/resolveByMessageId', failedMessage.payload.id)
        })

      this.$store.commit('message/SET_STATE', {
        messages: [...entries, ...messages]
      })
    },

    async loadAdminChatFailedMessages (conversationId) {
      const messages = this.chatMessages
      const messageIds = new Set(messages.map(m => m.id))

      const failedMessages = await this.$store.dispatch('failedMessages/get', {
        conversationId,
        failedMessageType: FAILED_MESSAGE_TYPE.ADMIN_CHAT
      })

      const entries = _flow([
        fMsgs => {
          return fMsgs.filter(message => !messageIds.has(message.payload.id))
        },
        fMsgs => {
          return _orderBy(fMsgs, ['payload.timestampFailed'], ['asc'])
        },
        fMsgs => {
          return fMsgs.map((message, idx) => {
            const filesFromBase64 = message.payload.base64Files?.map(file => {
              return base64toFile(file.base64, {
                name: file.name
              })
            }) ?? []

            return {
              ...createAdminChatEntry({
                ...message.payload,
                sentAt: now() - (fMsgs.length - idx),
                files: filesFromBase64,
              }),
              sentStatus: MESSAGE_SEND_STATUS.FAILED
            }
          })
        }
      ])(failedMessages)

      failedMessages
        .filter(x => messageIds.has(x.payload.id))
        .forEach(failedMessage => {
          this.$store.dispatch('failedMessages/resolveByMessageId', failedMessage.payload.id)
        })

      this.$store.commit('chat/APPEND_MESSAGE', entries)
    },

  }, PREFIX)
};