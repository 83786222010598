import moment from 'moment';
import { mapState } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import IconArticle from '@/components/icon/IconArticle.vue';
import { parseHtml } from '@corefront/utils/parseHtml';
import { trimHtmlTags } from '@corefront/utils/trimHtmlTags';
import _isEmpty from 'lodash/isEmpty';
export default {
  components: {
    InfiniteLoading,
    IconArticle
  },
  data() {
    return {
      scrollbarOptions: {
        suppressScrollX: true
      },
      search: '',
      selectedArticle: {},
      refreshStatus: 'idle',
      filteredArticles: [],
      loading: false,
      infiniteTag: ''
    };
  },
  computed: {
    ...mapState('articles', {
      showDialogStore: 'articleDialog',
      articles: 'articles',
      admins: 'admins'
    }),
    displayArticles() {
      return this.search.length > 0 ? this.filteredArticles : this.articles;
    },
    showDialog: {
      get() {
        return this.showDialogStore;
      },
      set(value) {
        this.$store.commit('articles/SET_OPEN_ARTICLE', value);
      }
    }
  },
  watch: {
    search(val) {
      this.selectedArticle = {};
      if (val) {
        this.filteredArticles = this.articles.filter(article => {
          return article.title.toLowerCase().includes(val.toLowerCase()) || article.body.toLowerCase().includes(val.toLowerCase());
        });
      } else {
        this.filteredArticles = this.articles;
      }
    },
    async showDialog(show) {
      this.selectedArticle = {};
      this.search = '';
      if (show) {
        this.loading = true;
        await this.$store.dispatch('articles/fetchArticles');
        await this.$store.dispatch('articles/fetchArticleAdmins');
        if (_isEmpty(this.selectedArticle)) {
          this.selectedArticle = this.articles[0];
        }

        // Assigning a new value to the infiniteTag will reset the infinite loading status
        this.infiniteTag = new Date();
        this.loading = false;
      }
    }
  },
  methods: {
    parseHtml,
    sendToChat() {
      if (this.loading) {
        return;
      }
      const articleToSend = {
        id: this.selectedArticle.id,
        title: this.selectedArticle.title,
        body: this.selectedArticle.body,
        bodyNoHtml: trimHtmlTags(this.selectedArticle.body),
        url: this.selectedArticle.url
      };
      this.$emit('add-article', articleToSend);
      this.showDialog = false;
    },
    getAdminName(author_id) {
      const admin = this.admins.find(admin => admin.id === author_id.toString());
      return admin.name;
    },
    setActiveItem(article) {
      this.selectedArticle = article;
    },
    formatDate(date) {
      return !date ? '' : moment(date).fromNow();
    },
    async refreshArticles() {
      this.selectedArticle = {};
      this.loading = true;
      this.$store.commit('articles/SET_ARTICLES', []);
      this.refreshStatus = 'loading';
      await this.$store.dispatch('articles/fetchArticles');
      this.selectedArticle = this.articles[0];
      this.refreshStatus = 'complete';
      this.loading = false;

      // Assigning a new value to the infiniteTag will reset the infinite loading status
      this.infiniteTag = new Date();
      setTimeout(() => {
        this.refreshStatus = 'idle';
      }, 2000);
    },
    async searchArticles() {
      this.loading = true;
      const data = await this.$store.dispatch('articles/searchArticles', this.search);
      this.filteredArticles = data;
      this.loading = false;
    },
    async loadMoreArticles($state) {
      const data = await this.$store.dispatch('articles/fetchArticles', {
        offset: this.articles.length
      });
      if (data.length == 0) {
        $state.complete();
      } else {
        $state.loaded();
      }
    }
  }
};