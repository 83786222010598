var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-row", {
    staticClass: "bg-primary tw-text-white",
    staticStyle: {
      height: "100vh"
    }
  }, [_c("b-col", {
    staticClass: "!tw-p-10",
    attrs: {
      cols: "12"
    }
  }, [_c("b-navbar-brand", {
    staticClass: "tw-mt-10 tw-mb-20",
    attrs: {
      to: "/"
    }
  }, [_c("svg", {
    attrs: {
      width: "35",
      height: "28",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("path", {
    attrs: {
      d: "M30.846 19.216 18.232 6.603h-1.464L4.152 19.218 4.126 0H0v28h1.588l15.913-16.792 15.863 16.687.1.105H35V0h-4.18l.026 19.216Z",
      fill: "#ffffff"
    }
  })])]), !_vm.isPasswordTemporary ? _c("div", [_c("div", {
    staticClass: "tw-mb-6"
  }, [_c("h1", {
    staticClass: "tw-mb-3 heading tw-text-[38px] tw-leading-[47px] tw-text-white"
  }, [_vm._v(" Good to see you ")]), _c("p", {}, [_vm._v(" Please sign in to continue ")]), _c("b-alert", {
    class: {
      "tw-bg-[#DB3B3B] tw-border-none": _vm.alert.type === "danger"
    },
    attrs: {
      show: _vm.alert.show,
      variant: _vm.alert.type
    },
    domProps: {
      innerHTML: _vm._s(_vm.alert.message)
    }
  })], 1), _c("div", [_c("a", {
    staticClass: "tw-block",
    attrs: {
      href: `https://${_vm.cognitoUserPoolDomain}/oauth2/authorize?identity_provider=Google&redirect_uri=${_vm.window.location.origin}/code-exchange&response_type=CODE&client_id=${_vm.cognitoClientId}&scope=email openid phone`
    }
  }, [_c("b-button", {
    attrs: {
      variant: "secondary",
      pill: "",
      block: ""
    }
  }, [_c("div", {
    staticClass: "tw-py-1 tw-flex tw-items-center tw-justify-center tw-gap-2"
  }, [_c("svg", {
    attrs: {
      width: "1rem",
      height: "1rem",
      viewBox: "0 0 14 14",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("path", {
    attrs: {
      d: "M13.3736 5.72697H12.85V5.7H7V8.3H10.6735C10.1376 9.81352 8.69747 10.9 7 10.9C4.84622 10.9 3.1 9.15377 3.1 7C3.1 4.84622 4.84622 3.1 7 3.1C7.99417 3.1 8.89865 3.47505 9.58733 4.08767L11.4258 2.24915C10.2649 1.16722 8.7121 0.5 7 0.5C3.41037 0.5 0.5 3.41037 0.5 7C0.5 10.5896 3.41037 13.5 7 13.5C10.5896 13.5 13.5 10.5896 13.5 7C13.5 6.56417 13.4551 6.13875 13.3736 5.72697Z",
      fill: "#FFC107"
    }
  }), _c("path", {
    attrs: {
      d: "M1.25 3.97457L3.38558 5.54075C3.96342 4.1101 5.36288 3.1 7.00055 3.1C7.99473 3.1 8.8992 3.47505 9.58788 4.08767L11.4264 2.24915C10.2655 1.16722 8.71265 0.5 7.00055 0.5C4.5039 0.5 2.33875 1.90952 1.25 3.97457Z",
      fill: "#FF3D00"
    }
  }), _c("path", {
    attrs: {
      d: "M6.99952 13.4998C8.67847 13.4998 10.204 12.8573 11.3574 11.8124L9.34569 10.1101C8.67128 10.6233 7.84698 10.9007 6.99952 10.8998C5.30887 10.8998 3.87334 9.82181 3.33254 8.31738L1.21289 9.95051C2.28864 12.0555 4.47329 13.4998 6.99952 13.4998Z",
      fill: "#4CAF50"
    }
  }), _c("path", {
    attrs: {
      d: "M13.3736 5.72717H12.85V5.7002H7V8.3002H10.6735C10.4171 9.02053 9.95534 9.64998 9.3452 10.1108L9.34617 10.1101L11.3579 11.8125C11.2156 11.9418 13.5 10.2502 13.5 7.0002C13.5 6.56437 13.4551 6.13895 13.3736 5.72717Z",
      fill: "#1976D2"
    }
  })]), _c("p", {
    staticClass: "tw-mb-0 tw-text-sm tw-font-gerstner tw-uppercase tw-tracking-wider"
  }, [_vm._v("Sign in with Google")])])])], 1)]), _c("div", {
    staticClass: "tw-flex tw-items-center tw-gap-4 tw-my-6"
  }, [_c("div", {
    staticClass: "tw-flex-1 tw-border-t tw-border-[#738582]"
  }), _c("span", {
    staticClass: "tw-text-[#738582] tw-font-medium tw-text-lg"
  }, [_vm._v("or")]), _c("div", {
    staticClass: "tw-flex-1 tw-border-t tw-border-[#738582]"
  })]), _vm.showUsernamePasswordMethod ? [_c("div", {
    staticClass: "mb-4",
    attrs: {
      role: "group"
    }
  }, [_c("label", {
    attrs: {
      for: "email"
    }
  }, [_vm._v("Email Address:")]), _c("b-form-input", {
    staticClass: "tw-px-0 tw-border-b tw-border-white !tw-text-white focus:tw-border-white focus:tw-bg-transparent",
    attrs: {
      id: "email",
      type: "email",
      state: _vm.emailState,
      disabled: _vm.processing,
      required: ""
    },
    on: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c("b-form-invalid-feedback", {
    attrs: {
      id: "input-live-feedback"
    }
  }, [_vm._v(" The email must have '@email.com' ")])], 1), _c("div", {
    staticClass: "mb-4",
    attrs: {
      role: "group"
    }
  }, [_c("div", {
    staticClass: "row",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("label", {
    staticClass: "col",
    attrs: {
      for: "password"
    }
  }, [_vm._v("Password:")])]), _c("b-form-input", {
    staticClass: "tw-px-0 tw-border-b tw-border-white !tw-text-white focus:tw-border-white focus:tw-bg-transparent",
    attrs: {
      id: "password",
      type: "password",
      state: _vm.passwordState,
      disabled: _vm.processing,
      required: ""
    },
    on: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _c("b-form-invalid-feedback", {
    attrs: {
      id: "input-live-feedback"
    }
  }, [_vm._v(" Min. 8 characters with at least one capital letter, a number and a special character ")])], 1), _c("b-button", {
    staticClass: "tw-mb-3 tw-px-10",
    attrs: {
      type: "submit",
      pill: "",
      variant: "secondary",
      size: "lg",
      disabled: _vm.processing
    },
    on: {
      click: _vm.login
    }
  }, [_vm._v(" " + _vm._s(_vm.processing ? "Please wait" : "Login") + " ")])] : _c("div", {
    staticClass: "tw-mb-4"
  }, [_vm._v(" Log in with your user name and password, "), _c("span", {
    staticClass: "tw-cursor-pointer tw-underline",
    on: {
      click: function ($event) {
        _vm.showUsernamePasswordMethod = true;
      }
    }
  }, [_vm._v("here")]), _vm._v(". ")])], 2) : _c("div", [_c("h1", {
    staticClass: "display-4 mb-3 heading color-inherit"
  }, [_vm._v(" Good to see you ")]), _c("p", [_vm._v(" You entered a temporary password. Kindly provide a new password for security purposes. ")]), _c("div", {
    staticClass: "mb-4",
    attrs: {
      role: "group"
    }
  }, [_c("div", {
    staticClass: "row",
    staticStyle: {
      "align-items": "center"
    }
  }, [_c("label", {
    staticClass: "col",
    attrs: {
      for: "password"
    }
  }, [_vm._v("New Password:")])]), _c("b-form-input", {
    staticClass: "tw-px-0 tw-border-b tw-border-[#CDFCB1] focus:tw-border-[#CDFCB1] focus:tw-bg-transparent",
    attrs: {
      id: "password",
      type: "password",
      disabled: _vm.processing,
      required: ""
    },
    on: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.loginWithNewPassword.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newPassword,
      callback: function ($$v) {
        _vm.newPassword = $$v;
      },
      expression: "newPassword"
    }
  }), _c("b-form-invalid-feedback", {
    attrs: {
      id: "input-live-feedback"
    }
  }, [_vm._v(" Min. 8 characters with at least one capital letter, a number and a special character ")])], 1), _c("b-button", {
    staticClass: "mb-3",
    attrs: {
      type: "submit",
      pill: "",
      variant: "secondary",
      size: "lg",
      disabled: _vm.processing
    },
    on: {
      click: _vm.loginWithNewPassword
    }
  }, [_vm._v(" " + _vm._s(_vm.processing ? "Please wait" : "Continue") + " ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };