/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./CardPharmacy.vue?vue&type=template&id=83c655a2&scoped=true"
import script from "./CardPharmacy.vue?vue&type=script&lang=js"
export * from "./CardPharmacy.vue?vue&type=script&lang=js"
import style0 from "./CardPharmacy.vue?vue&type=style&index=0&id=83c655a2&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83c655a2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src179636757/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('83c655a2')) {
      api.createRecord('83c655a2', component.options)
    } else {
      api.reload('83c655a2', component.options)
    }
    module.hot.accept("./CardPharmacy.vue?vue&type=template&id=83c655a2&scoped=true", function () {
      api.rerender('83c655a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/patients/partials/CardPharmacy.vue"
export default component.exports