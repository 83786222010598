import { trimHtmlTags } from '@corefront/utils/trimHtmlTags';
import { format } from 'date-fns';
import { ActionService } from '@/services/action.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    },
    customId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showDialog: false,
      loaded: false,
      alert: {
        show: false,
        message: ''
      },
      headers: [{
        key: 'timestampcreated',
        label: 'Date'
      }, {
        key: 'noteby',
        label: 'Author'
      }, {
        key: 'notes',
        label: 'Note'
      }],
      notes: []
    };
  },
  watch: {
    async showDialog() {
      if (this.showDialog) {
        const id = this.customId ? this.customId : this.rowData.id;
        console.log(id);
        const {
          data
        } = await ActionService.getActionNote(id);
        this.notes = data.reverse();
        if (this.notes.length == 0) {
          this.alert.show = true;
          this.alert.message = 'This patient does not have any notes yet.';
        }
        this.loaded = true;
      } else {
        this.alert.show = false;
        this.notes = [];
        this.loaded = false;
      }
    }
  },
  methods: {
    trimHtmlTags,
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },
    getDate(date) {
      console.log(date);
      if (date == '-') {
        return '-';
      }
      return format(new Date(date * 1000), 'M/dd/yyyy');
    },
    openActionDialog() {
      this.$emit('open-action');
    }
  }
};