import "core-js/modules/es.array.push.js";
import FormMixins from '@/components/mixins/FormMixins';
import { AuthService } from '@/services/auth.service';
export default {
  name: 'Login',
  mixins: [FormMixins],
  data() {
    return {
      alert: {
        show: false,
        type: 'warning',
        message: ''
      },
      email: '',
      password: '',
      processing: false,
      isPasswordTemporary: false,
      newPassword: '',
      showUsernamePasswordMethod: false,
      cognitoUserPoolDomain: process.env.VUE_APP_COGNITO_USER_POOL_DOMAIN,
      cognitoClientId: process.env.VUE_APP_COGNITO_CLIENT_ID
    };
  },
  mounted() {
    if (this.$route.query.expired) {
      this.alert.show = true;
      this.alert.message = 'Your session has expired. Please login again.';
    }
    if (this.$route.query.error === 'code_exchange_failed') {
      this.alertMessage('danger', '<strong class=\'tw-font-atten-bold\'>Access Denied</strong>: Your email is not authorized to access this portal. For further assistance, <a href=\'https://bywinona.slack.com/archives/C03MLH7SALQ\' target=\'_blank\' class=\'tw-text-white tw-undeline hover:tw-text-red-200 tw-border-b hover:tw-border-none\'>contact</a> support.');
    }
  },
  methods: {
    alertMessage(type, message) {
      this.alert = {
        type,
        message,
        show: true
      };
    },
    async login() {
      this.alert.show = false;
      this.processing = true;
      const loginParams = {
        email: this.email,
        password: this.password,
        role: 'ADMIN'
      };
      try {
        var _res$data, _res$data$body;
        const res = await AuthService.login(loginParams);
        if (res !== null && res !== void 0 && (_res$data = res.data) !== null && _res$data !== void 0 && (_res$data$body = _res$data.body) !== null && _res$data$body !== void 0 && _res$data$body.idToken) {
          var _res$data2, _res$data2$body;
          localStorage.setItem('accessToken', res === null || res === void 0 ? void 0 : (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : (_res$data2$body = _res$data2.body) === null || _res$data2$body === void 0 ? void 0 : _res$data2$body.idToken);
          this.$router.push(this.$route.query.goToUrl || '/');
        }
        if (res.data.message === 'Please provide your new password') {
          this.isPasswordTemporary = true;
        }
      } catch (err) {
        this.alert.show = true;
        this.alert.message = err.message;
      }
      this.processing = false;
    },
    async loginWithNewPassword() {
      this.alert.show = false;
      this.processing = true;
      try {
        var _res$data3;
        const res = await AuthService.updateTemporaryPassword({
          email: this.email,
          tmppassword: this.password,
          password: this.newPassword
        });
        if (res !== null && res !== void 0 && (_res$data3 = res.data) !== null && _res$data3 !== void 0 && _res$data3.idToken) {
          this.$router.push('/');
        }
      } catch (err) {
        console.log(err);
        this.alert.show = true;
        this.alert.message = err.message;
        this.processing = false;
      }
    }
  }
};