var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mx-3 pt-4"
  }, [_c("PatientsSidePanelFilter", {
    attrs: {
      value: true
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };