import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/web.dom-exception.stack.js";
// sample filters
// const filters = [
//   {
//     field: 'status',
//     label: 'Status',
//     type: 'string',
//   },
//   {
//     field: 'state',
//     label: 'State',
//     type: 'select',
//     options: [
//       {
//         label: 'Active',
//         value: 'active'
//       },
//       {
//         label: 'Inactive',
//         value: 'inactive'
//       }
//     ]
//   },
//   {
//     field: 'states',
//     label: 'States',
//     type: 'multiselect',
//     options: Array.from({ length: 100 }, (_, i) => ({
//       label: `State ${i + 1}`,
//       value: `state-${i + 1}`
//     }))
//   }
// ]

// libs
import _isEmpty from 'lodash/isEmpty';

// utils
import { tryToCatch } from '@corefront/utils/tryToCatch';
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedFilter: null,
      filterObject: {}
    };
  },
  computed: {
    fieldOptionsByValue() {
      return this.filters.reduce((acc, filter) => {
        if (filter.options) {
          acc[filter.field] = filter.options.reduce((acc, option) => {
            acc[option.value] = option.label;
            return acc;
          }, {});
        }
        return acc;
      }, {});
    },
    filterObjectPreviews() {
      return this.filters.reduce((acc, filter) => {
        const value = this.filterObject[filter.field];
        const isValueArrray = Array.isArray(value);
        if (!value || isValueArrray && !value.length) {
          return acc;
        }
        if (isValueArrray) {
          const firstItem = this.fieldOptionsByValue[filter.field][value[0]];
          if (value.length === 1) {
            return {
              ...acc,
              [filter.field]: firstItem
            };
          }
          const remaining = value.length - 1;
          const preview = `${firstItem} and ${remaining} other${remaining === 1 ? '' : 's'}`;
          return {
            ...acc,
            [filter.field]: preview
          };
        }
        return {
          ...acc,
          [filter.field]: value || ''
        };
      }, {});
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    '$route.query.q'() {
      this.reset();
    },
    model(value) {
      if (!value) {
        this.reset();
      }
    }
  },
  mounted() {
    this.initFilterObject();
  },
  methods: {
    reset() {
      this.selectedFilter = null;
      this.initFilterObject();
    },
    onCancel() {
      this.initFilterObject();
      this.model = false;
    },
    async initFilterObject() {
      const [error, filter] = await tryToCatch(() => JSON.parse(atob(this.$route.query.q)));
      if (error) {
        this.filterObject = {};
        return this.$router.push({
          query: {
            ...this.$route.query,
            q: undefined
          }
        });
      }
      this.filterObject = filter;
    },
    onSubmitFilter() {
      const q = _isEmpty(this.filterObject) ? undefined : btoa(JSON.stringify(this.filterObject));
      this.$router.push({
        query: {
          ...this.$route.query,
          q
        }
      });
      this.model = false;
    },
    clearAll() {
      this.filterObject = {};
      this.$router.push({
        query: {
          ...this.$route.query,
          q: undefined
        }
      });
    }
  }
};