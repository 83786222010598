var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("b-toast", {
    attrs: {
      id: "viewToast",
      variant: "danger",
      "no-close-button": "",
      "auto-hide-delay": "2500",
      toaster: "b-toaster-top-center",
      solid: ""
    }
  }, [_vm._v(" " + _vm._s(_vm.toastMessage) + " ")]), _c("b-row", {
    staticClass: "content tw-flex tw-mx-6",
    attrs: {
      id: _vm.$_message_CHAT_CONTAINER_ID
    }
  }, [_c("b-col", {
    staticClass: "tw-pr-0",
    attrs: {
      cols: "12",
      lg: "3"
    }
  }, [_c("MessagesSidebar", {
    attrs: {
      conversation: _vm.conversation
    },
    on: {
      "select-conversation": _vm.openConversation
    }
  })], 1), _c("b-col", {
    staticClass: "tw-hidden lg:tw-block",
    attrs: {
      cols: "12",
      lg: "9"
    }
  }, [!_vm.conversationDoctor || _vm.loading ? [_c("b-card", {
    staticClass: "tw-mb-0 tw-bg-transparent tw-border-l-0 tw-border-r-0",
    attrs: {
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("b-row", {
          attrs: {
            "align-v": "center"
          }
        }, [_c("b-col", [_c("h3", {
          staticClass: "tw-mb-0"
        }, [_vm._v(" Conversation ")])])], 1)];
      },
      proxy: true
    }], null, false, 3182929535)
  }, [_c("div", {
    staticClass: "chat-box tw-flex tw-justify-center tw-items-center tw-h-[calc(100vh-232px)]"
  }, [_vm.loading ? _c("h3", {
    staticClass: "tw-text-davys-grey"
  }, [_vm._v(" Loading conversation details ")]) : _c("h3", {
    staticClass: "tw-text-davys-grey"
  }, [_vm._v(" Select patient to view conversation ")])])])] : [_c("b-card", {
    staticClass: "tw-mb-0 tw-border-l-0 tw-border-r-0 tw-border-b-0 chats",
    attrs: {
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c("div", [_c("a", {
          attrs: {
            href: "javascript:;"
          },
          on: {
            click: _vm.viewPatientInfo
          }
        }, [_c("h3", {
          staticClass: "tw-mb-0"
        }, [_vm._v(" " + _vm._s(_vm.patientName) + " ")])])])];
      },
      proxy: true
    }])
  }, [_c("b-card-body", {
    attrs: {
      "body-class": "card-chat-body"
    }
  }, [_c("ChatThread", {
    staticClass: "tw-w-full",
    style: {
      height: `${_vm.$_message_conversationListHeight}px`
    },
    attrs: {
      messages: _vm.messages,
      "has-next-page": !!_vm.messagesNextPagePointer,
      "date-resolver": _vm.chatThreadDateResolver
    },
    on: {
      "next-page": _vm.fetchNextPage
    },
    scopedSlots: _vm._u([{
      key: "bubble",
      fn: function ({
        message
      }) {
        return [_c("MessagesChatBubble", {
          attrs: {
            message: message,
            members: _vm.conversationMembers
          }
        })];
      }
    }])
  })], 1), _c("b-card-footer", {
    staticClass: "border-t-0 tw-border-0",
    attrs: {
      id: _vm.$_message_CHAT_FOOTER_ID,
      "footer-class": "custom-footer"
    }
  }, [_c("div", {
    staticClass: "tw-p-3 tw-pb-0"
  }, [_c("ComposerEditor", {
    ref: "composer",
    attrs: {
      "hide-bubble-menu": ""
    },
    on: {
      send: _vm.sendMessage
    },
    model: {
      value: _vm.newMessage,
      callback: function ($$v) {
        _vm.newMessage = $$v;
      },
      expression: "newMessage"
    }
  }), _c("div", {
    staticClass: "tw-flex tw-justify-end"
  }, [!_vm.socket.isReady ? _c("span", {
    staticClass: "tw-text-red-500 tw-text-xs"
  }, [_vm._v(" Server cannot be reached ")]) : _vm._e()])], 1)])], 1)]], 2)], 1), _vm.conversation.patientid ? _c("PatientsInfoSidePanel", {
    ref: "PatientsInfoSidePanel",
    attrs: {
      "patient-id": _vm.conversation.patientid,
      "update-url": false
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };