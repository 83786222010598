var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tw-p-6 chat-message-input-area"
  }, [_c("div", {
    class: _vm.messageCategory !== "chat" ? "tw-bg-[#FDEDAF]" : ""
  }, [_c("div", [_c("ComposerEditor", {
    ref: "composer",
    attrs: {
      "hide-bubble-menu": "",
      articles: _vm.articles,
      tools: _vm.composerTools,
      "disable-file-upload": _vm.messageCategory === "note"
    },
    on: {
      "update:articles": function ($event) {
        _vm.articles = $event;
      },
      send: _vm.sendMessage,
      input: function ($event) {
        return _vm.$emit("input");
      },
      "attach-files": f => _vm.files = f
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [!_vm.notesOnly ? _c("div", {
          staticClass: "tw-flex tw-justify-between tw-items-center"
        }, [_c("div", {
          staticClass: "tw-flex tw-items-center tw-space-x-2"
        }, [_vm.macroLoading ? _c("b-spinner", {
          attrs: {
            small: "",
            variant: "muted"
          }
        }) : _c("div", {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              hover: true
            }
          }],
          staticClass: "tw-cursor-pointer tw-text-muted",
          attrs: {
            title: "Insert macro"
          },
          on: {
            click: _vm.openMacroDialog
          }
        }, [_c("IconMacro")], 1), _c("div", {
          staticClass: "tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer",
          on: {
            click: _vm.openArticleDialog
          }
        }, [_c("IconArticle", {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              hover: true
            }
          }],
          staticClass: "tw-text-muted",
          attrs: {
            title: "Insert article"
          }
        })], 1), _c("div", {
          staticClass: "tw-flex tw-items-center tw-space-x-2 tw-cursor-pointer",
          on: {
            click: _vm.toggleMessageCategory
          }
        }, [_vm.messageCategory === "chat" ? _c("IconChat", {
          staticClass: "tw-text-[#828282]"
        }) : _c("IconChatNote", {
          staticClass: "tw-text-[#828282]"
        }), _c("p", {
          staticClass: "tw-mb-0 tw-text-[#828282] tw-select-none"
        }, [_vm._v(" " + _vm._s(_vm.messageCategory === "chat" ? "Winona Chat" : "Notes") + " ")])], 1)], 1)]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };