var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    staticClass: "p-4",
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "lg"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-row", {
    attrs: {
      "align-h": "end"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "1"
    }
  }, [_c("b-button", {
    attrs: {
      variant: "link"
    },
    on: {
      click: _vm.close
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      variant: "primary"
    }
  })], 1)], 1)], 1), _c("b-row", [_c("b-col", [_c("b-alert", {
    attrs: {
      show: _vm.alert.show,
      variant: "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")])], 1)], 1), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("h4", [_vm._v("Forwarded Request")])]), _c("b-col", {
    attrs: {
      cols: "4"
    }
  }, [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Doctor")]), _c("br"), _c("p", [_vm._v(_vm._s(_vm.forwardedMessageData.doctorname))])]), _c("b-col", {
    attrs: {
      cols: "4",
      offset: "1"
    }
  }, [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Date")]), _c("br"), _c("p", [_vm._v(" " + _vm._s(_vm.getDate(_vm.forwardedMessageData.timestampcreated)) + " ")])])], 1), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "5",
      offset: "3"
    }
  }, [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Patient")]), _c("br"), _vm.rowData ? _c("p", [_vm._v(" " + _vm._s(_vm.forwardedMessageData.patientname) + " ")]) : _vm._e()]), _c("b-col", [_c("b-button", {
    attrs: {
      variant: "primary",
      pill: "",
      size: "lg",
      block: ""
    },
    on: {
      click: function ($event) {
        return _vm.redirectToIntercom(_vm.forwardedMessageData.patientid);
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/intercom.svg"),
      width: "120"
    }
  })])], 1)], 1), _c("b-row", [_c("b-col", {
    attrs: {
      offset: "3"
    }
  }, [_c("span", {
    staticClass: "text-muted"
  }, [_vm._v("Doctor Notes")]), _c("p", [_vm._v(_vm._s(_vm.forwardedMessageData.doctornotes))])])], 1), _c("hr"), _c("b-row", {
    staticClass: "chat-background"
  }, [_c("b-col", [_vm.isLoading ? _c("div", {
    staticClass: "tw-w-full tw-grid tw-place-content-center"
  }, [_c("IconLoading")], 1) : [_c("ChatThread", {
    staticClass: "tw-w-full",
    attrs: {
      messages: _vm.forwardedMessageData.messagelist || [],
      "date-resolver": _vm.chatThreadDateResolver
    },
    scopedSlots: _vm._u([{
      key: "bubble",
      fn: function ({
        message
      }) {
        return [_c("ChatBubble", {
          attrs: {
            "communication-role": _vm.getCommunicationRole(message),
            "avatar-url": _vm.profileUrlHashmaps[message.userid],
            message: message.message,
            "sent-date-unix": message.sentat,
            attachments: message.attachment,
            "sender-name": _vm.namesHashmap[message.userid],
            "send-status": _vm.MESSAGE_SEND_STATUS.SENT,
            "hide-name": "",
            "bubble-background-class": _vm.getBubbleBackgroundClass(message)
          }
        })];
      }
    }])
  })]], 2)], 1), _c("b-row", {
    staticClass: "mt-3"
  }, [_c("b-col", {
    attrs: {
      offset: "4"
    }
  }, [_vm.showMessageButton ? _c("b-button", {
    attrs: {
      variant: "outline-dark",
      block: "",
      pill: "",
      disabled: _vm.viewOnly || _vm.processing
    },
    on: {
      click: _vm.redirectToMessages
    }
  }, [_vm._v(" Message Doctor ")]) : _vm._e()], 1), _c("b-col", [_c("b-button", {
    attrs: {
      variant: "secondary",
      block: "",
      pill: "",
      disabled: _vm.viewOnly || _vm.processing
    },
    on: {
      click: _vm.completeRequest
    }
  }, [_vm._v(" Complete ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };