var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("svg", {
    attrs: {
      height: _vm.height,
      width: _vm.width,
      xmlns: "http://www.w3.org/2000/svg",
      viewBox: "0 0 20.82 18.49",
      fill: "currentColor"
    }
  }, [_c("path", {
    staticClass: "cls-1",
    attrs: {
      fill: "currentColor",
      d: "M20.81,8.05c0,1.98.01,3.96,0,5.94,0,1.41-.54,1.92-1.95,1.92-2.23,0-4.48-.12-6.41,1.32-.33.24-.6.57-.93.8-.7.5-1.52.69-2.15.03-1.87-1.96-4.23-2.19-6.72-2.16C.29,15.93,0,15.64,0,13.31c0-3.71,0-7.42,0-11.14C0,.51.46.04,2.13.04c1.85,0,3.71-.1,5.45.8,1.14.59,1.71,1.4,1.69,2.74-.06,3.22-.05,6.44.02,9.65.01.52.34,1.24.75,1.51.71.46,1.48-.21,1.51-1.31.05-1.85.02-3.71.02-5.57,0-1.53.02-3.05,0-4.58-.02-1.02.46-1.7,1.31-2.21C15.02-.23,17.38-.07,19.72.16c.9.09,1.08.91,1.09,1.7,0,2.06,0,4.12,0,6.19Z"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };