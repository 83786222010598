// libs
import { mapState } from 'vuex';
import _get from 'lodash/get';

// components
import ChatBubble from '@corefront/components-v2/Chat/ChatBubble';
import DoctorRequestDialog from '@/components/patients/DoctorRequestDialog';

// constants
import { MESSAGE_TYPE, COMMUNICATION_ROLE, CHAT_TYPE, ROLES } from '@corefront/constant/messages';

// mixins
import { failedMessagesMixin } from '@/mixins/failedMessages';
export default {
  components: {
    ChatBubble,
    DoctorRequestDialog
  },
  mixins: [failedMessagesMixin],
  props: {
    message: {
      type: Object,
      required: true
    },
    members: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedForwardedRequest: null
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('failedMessages', ['failedMessages']),
    listeners() {
      return {
        ...this.$listeners
      };
    },
    COMMUNICATION_ROLE: () => COMMUNICATION_ROLE,
    MESSAGE_TYPE: () => MESSAGE_TYPE,
    CHAT_TYPE: () => CHAT_TYPE,
    ROLES: () => ROLES,
    commonAttrs() {
      return {
        retryFailedFn: this.onRetryFailed,
        deleteFailedFn: this.onDeleteFailed,
        senderName: this.senderName,
        bubbleBackgroundClass: this.bubbleBackgroundClass
      };
    },
    bubbleBackgroundClass() {
      if (this.message.type === MESSAGE_TYPE.FORWARD_MESSAGE) {
        return 'tw-bg-mint-cream';
      }
      return this.communicationRole === COMMUNICATION_ROLE.RECEIVER ? 'tw-bg-silver-gray' : 'tw-bg-peach';
    },
    reversedForwardMessages() {
      const forwardMessage = _get(this.message, 'custommessage.forwardmessage.messagelist', []);
      return [...forwardMessage].reverse();
    },
    communicationRole() {
      return this.getCommunicationRole(this.message);
    },
    profileUrl() {
      return this.member ? this.member.profileUrl || require('@/assets/images/anon.jpeg') : '';
    },
    senderName() {
      const {
        message,
        member
      } = this;
      if (message.type === MESSAGE_TYPE.TEXT || message.type === MESSAGE_TYPE.ATTACHMENT) {
        return member.role === ROLES.DOCTOR ? `Dr. ${member.firstName} ${member.lastName}`.trim() : `${member.firstName} ${member.lastName}`;
      }
      if (message.type === MESSAGE_TYPE.FOLLOWUP) {
        return 'Follow Up';
      }
      if (message.type === MESSAGE_TYPE.PRESCRIPTION_CONFIRMATION) {
        return 'Prescription Confirmation';
      }
      if (message.type === MESSAGE_TYPE.PRESCRIPTION_FOLLOWUP) {
        return 'Prescription Follow Up';
      }
      if (message.type === MESSAGE_TYPE.FORWARD_MESSAGE) {
        return 'Forwarded Message';
      }
      return 'Unknown';
    },
    member() {
      switch (this.message.type) {
        case MESSAGE_TYPE.FOLLOWUP:
          return {
            firstName: 'Follow Up'
          };
        case MESSAGE_TYPE.PRESCRIPTION_CONFIRMATION:
          return {
            firstName: 'Prescription Confirmation'
          };
        case MESSAGE_TYPE.PRESCRIPTION_FOLLOWUP:
          return {
            firstName: 'Prescription Follow Up'
          };
        case MESSAGE_TYPE.FORWARD_MESSAGE:
          return {
            firstName: 'Forwarded Message'
          };
        default:
          {
            const foundMember = this.members.find(x => x.id === this.message.userid);
            return foundMember || {
              firstName: 'Unknown'
            };
          }
      }
    }
  },
  methods: {
    getForwardBubbleBackgroundClass(msg) {
      const communicationRole = msg.usertype === ROLES.PATIENT ? COMMUNICATION_ROLE.RECEIVER : COMMUNICATION_ROLE.SENDER;
      return communicationRole === COMMUNICATION_ROLE.RECEIVER ? 'tw-bg-white' : 'tw-bg-peach';
    },
    openRequestDialog(fwd) {
      this.selectedForwardedRequest = fwd;
      this.$refs.DoctorRequestDialog.showDialog = true;
    },
    getCommunicationRole(msg) {
      if (msg.type === MESSAGE_TYPE.FOLLOWUP) {
        return COMMUNICATION_ROLE.RECEIVER;
      }
      return this.currentUser.id === msg.userid ? COMMUNICATION_ROLE.SENDER : COMMUNICATION_ROLE.RECEIVER;
    },
    async onDeleteFailed() {
      const confirm = await this.$coreConfirm({
        message: 'Are you sure you want to delete this message?',
        confirmText: 'Delete',
        cancelText: 'Cancel'
      });
      if (!confirm) {
        return;
      }
      const {
        id
      } = this.message;
      this.$_failedMessages_deleteLocalFailedMessage(id);
      await this.$store.dispatch('failedMessages/resolveByMessageId', id);
      this.$store.commit('message/DELETE_MESSAGE_BY_ID', id);
      this.$store.commit('message/UPDATE_CONVERSATION_BY_ID', {
        id: this.message.conversationid || this.message.conversationId,
        hasfailedmessage: this.failedMessages.length > 0
      });
    },
    async onRetryFailed() {
      await this.$_failedMessages_sendAdminMessage({
        message: {
          ...this.message,
          sentat: null
        },
        isRetry: true
      });
      this.$store.commit('message/UPDATE_CONVERSATION_BY_ID', {
        id: this.message.conversationid || this.message.conversationId,
        hasfailedmessage: this.failedMessages.length > 0
      });
    }
  }
};