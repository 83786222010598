import { v4 as uuidv4 } from 'uuid';
import ChatMessagesColumn from '@/components/chat/ChatMessagesColumn.vue';
import SideModal from '@/components/modals/SideModal';
import socket from '@corefront/utils/socket';
import { CATEGORY, ROLES, TYPES } from '@corefront/constant/messages';
import { EVENTS } from '@corefront/constant/socket';
import { mapState } from 'vuex';
import { chatThread } from '@corefront/mixins-v2/chatThread';
export default {
  components: {
    SideModal,
    ChatMessagesColumn
  },
  mixins: [chatThread],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      conversation: {},
      selectedCategory: CATEGORY.ALL,
      onlineStatusResponse: null,
      showMessages: false,
      readOnly: false
    };
  },
  computed: {
    ...mapState('chat', ['statesAPC', 'selectedConversation']),
    ...mapState('patients', ['patient']),
    isShow: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    conversationData() {
      // Use store data if available
      return this.selectedConversation ? this.selectedConversation : this.conversation;
    }
  },
  mounted() {
    this.listenToSocketEvents();
    this.init();
  },
  methods: {
    async init() {
      // clear chat state
      this.$store.commit('chat/SET_STATE', {
        conversations: [],
        conversation: null,
        messages: [],
        selectedConversation: null
      });
      this.conversation = {};
      this.showMessages = false;
      this.readOnly = !this.statesAPC.includes(this.patient.state);
      const conversation = await this.$store.dispatch('chat/getConversationById', this.patient.id);
      if (conversation) {
        this.$store.commit('chat/SET_CONVERSATIONS', [conversation]);
        await this.$store.dispatch('chat/selectConversation', conversation);
      } else {
        this.conversation = {
          id: uuidv4(),
          patient: this.patient,
          seenBy: []
        };
        this.$store.dispatch('chat/selectConversation', this.conversation);
        this.$store.commit('chat/SET_CONVERSATIONS', [this.conversation]);
      }
      this.$store.commit('chat/SET_STATE', {
        selectedPatient: this.patient.id,
        messages: []
      });
      await this.$store.dispatch('chat/fetchMessages', {
        patientId: this.patient.id
      });
      this.showMessages = true;
      await this.$nextTick();
      await this.$_chatThread_scrollToBottom();
    },
    async listenToSocketEvents() {
      await socket.waitUntilReady();
      socket.ctx.on(EVENTS.ADMIN_CHAT_MESSAGE, message => {
        if (message.conversationId === this.patient.id) {
          this.$store.commit('chat/APPEND_MESSAGE', {
            type: message.type,
            id: message.id,
            data: {
              ...message,
              ...(message.senderRole === ROLES.PATIENT ? {
                patient: message.sender
              } : {
                admin: message.sender
              })
            }
          });
        } else {
          this.$store.dispatch('chat/fetchConversations', {
            type: this.selectedCategory === CATEGORY.ALL ? null : this.selectedCategory
          });
          this.$store.dispatch('chat/getMetrics');
        }
      });
      socket.ctx.on(EVENTS.PATIENT_NOTE_ADDED, note => {
        if (note.patientId === this.patient.id) {
          this.$store.dispatch('chat/appendMessage', {
            type: TYPES.NOTE,
            id: note.id,
            data: {
              ...note,
              type: TYPES.NOTE
            }
          });
        }
      });
      socket.ctx.on(EVENTS.ADMIN_CHAT_ASSIGNMENT_CHANGED, data => {
        if (!data.from && this.selectedCategory === CATEGORY.UNASSIGNED) {
          this.$store.commit('chat/REMOVE_CONVERSATION', data.patientId);
        }
      });
      socket.ctx.on(EVENTS.ADMIN_CHAT_CONVERSATION_DISMISSED, data => {
        this.$store.commit('chat/REMOVE_CONVERSATION', data.patientId);
      });
      socket.ctx.on(EVENTS.ADMIN_CHAT_CONVERSATION_REOPENED, data => {
        this.$store.commit('chat/REMOVE_CONVERSATION', data.patientId);
      });
      socket.ctx.on(EVENTS.ONLINE_STATUS, data => {
        this.onlineStatusResponse = data;
        this.$store.dispatch('chat/updateOnlineStatus', data);
      });
    }
  }
};