var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("SideModal", {
    staticStyle: {
      width: "60% !important",
      "margin-left": "0 !important"
    },
    attrs: {
      show: _vm.isShow,
      "show-header": false,
      "body-class": "apc-modal",
      title: "Chat",
      "z-index": 9999
    },
    on: {
      exit: function ($event) {
        _vm.isShow = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_vm.showMessages && _vm.patient ? _c("ChatMessagesColumn", {
          staticClass: "mt-3",
          attrs: {
            conversation: _vm.conversationData,
            category: _vm.selectedCategory,
            "is-messages-loaded": _vm.showMessages,
            "slide-mode": true,
            "read-only": _vm.readOnly
          },
          on: {
            "close-apc-slide": function ($event) {
              _vm.isShow = false;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };