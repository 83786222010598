import "core-js/modules/es.array.push.js";
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';

// components
import IconMacro from '@/components/icon/IconMacro.vue';
import IconChat from '@/components/icon/IconChat.vue';
import IconChatNote from '@/components/icon/IconChatNote.vue';
import IconArticle from '@/components/icon/IconArticle.vue';
import ComposerEditor from '@corefront/components-v2/Composer/ComposerEditor';

// constants
import { TYPES, EVENT_CATEGORY, EVENT_NAME } from '@corefront/constant/messages';
import { TOOLS } from '@corefront/constant/composer';

// mixins
import { failedMessagesMixin } from '@/mixins/failedMessages';
import { chatThread } from '@corefront/mixins-v2/chatThread';

// utils
import { renderTemplate } from '@corefront/utils/renderTemplate';
import { now } from '@corefront/utils/now';
export default {
  components: {
    IconMacro,
    IconChat,
    IconChatNote,
    IconArticle,
    ComposerEditor
  },
  mixins: [chatThread, failedMessagesMixin],
  props: {
    conversation: {
      type: Object,
      default: () => ({})
    },
    notesOnly: {
      type: Boolean,
      default: false
    },
    articles: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      files: [],
      message: '',
      messageCategory: 'chat',
      macroIds: [],
      macroLoading: false
    };
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('chat', ['messages', 'messageTemplate']),
    ...mapState('failedMessages', ['failedMessages']),
    composerTools() {
      if (this.messageCategory === 'note') {
        return [TOOLS.BOLD, TOOLS.ITALIC, TOOLS.LINK];
      }
      return [TOOLS.BOLD, TOOLS.ITALIC, TOOLS.LINK, TOOLS.ATTACHMENT, TOOLS.IMAGE];
    }
  },
  watch: {
    notesOnly(val) {
      this.messageCategory = val ? 'note' : 'chat';
    },
    messageCategory(newValue) {
      this.emitMessageCategory(newValue);
    },
    async messageTemplate(msgObject) {
      this.macroLoading = true;
      let msg = msgObject.content;
      msg = msg.replace(/(?:\r\n|\r|\n)/g, '<br>');
      if (!msg) {
        return;
      }
      const tagsToReplace = ['{patient}', '{admin}', '{doctor}'];
      if (tagsToReplace.some(tag => msg.includes(tag))) {
        msg = renderTemplate(msg, {
          patient: this.conversation.patient.firstName,
          admin: this.currentUser.firstname,
          doctor: this.conversation.doctor ? `Dr. ${this.conversation.doctor.doctorLastName}` : `Dr. ${this.conversation.patient.doctorname}`
        }, {
          pattern: /{(.*?)}/g
        });
      }
      if (!this.macroIds.includes(msgObject.id)) {
        this.macroIds.push(msgObject.id);
      }
      if (msgObject.articles && msgObject.articles.length > 0) {
        msgObject.articles.every(article => this.$emit('add-article', article));
      }
      this.message = this.message + msg + '\n';
      this.macroLoading = false;
    },
    message(msg) {
      if (msg == '') {
        this.macroIds = [];
      }
    },
    conversation() {
      this.messageCategory = 'chat';
    }
  },
  mounted() {
    if (this.notesOnly) {
      this.messageCategory = 'note';
    }
  },
  methods: {
    openMacroDialog() {
      this.$store.commit('chat/SET_OPEN_MACRO', true);
    },
    openArticleDialog() {
      this.$store.commit('articles/SET_OPEN_ARTICLE', true);
    },
    async sendChatMessage() {
      if (!this.message.trim().length && !this.files.length && !this.articles.length) {
        return;
      }
      const message = {
        id: uuidv4(),
        patientId: this.conversation.patient.id,
        sentAt: now(),
        message: this.message,
        messageTemplateIds: this.macroIds.length > 0 ? this.macroIds : undefined,
        senderId: this.currentUser.id,
        senderRole: this.currentUser.roletype,
        files: this.files,
        conversationId: this.conversation.id,
        sender: {
          id: this.currentUser.id,
          role: this.currentUser.roletype,
          firstName: this.currentUser.firstname,
          lastName: this.currentUser.lastname,
          email: this.currentUser.email
        },
        type: TYPES.MESSAGE,
        patient: this.conversation.patient
      };
      this.macroIds = [];
      this.message = '';
      this.$refs['composer'].clearAttachments();
      this.$refs['composer'].focus();
      if (this.articles.length) {
        let articlesToSend = [];
        articlesToSend = this.articles.map(article => article.id);
        message.articles = articlesToSend;

        // Queue articles to fetch if they haven't been loaded in already
        await this.$store.dispatch('articles/fetchArticlesById', {
          ids: message.articles
        });
      }
      this.$emit('update:articles', []);
      await this.$_failedMessages_sendAdminPatientChatMessage({
        message
      });
    },
    async sendNoteMessage() {
      if (!this.message.trim().length) {
        return;
      }
      const message = {
        id: uuidv4(),
        patientId: this.conversation.patient.id,
        sentAt: now(),
        message: this.message,
        authorId: this.currentUser.id,
        eventCategory: EVENT_CATEGORY.NOTES,
        event: EVENT_NAME.NOTE,
        eventName: EVENT_NAME.NOTE,
        noteBy: `${this.currentUser.firstname} ${this.currentUser.lastname}`,
        messageBody: this.message,
        timestampInteraction: now(),
        conversationId: this.conversation.id,
        type: TYPES.NOTE,
        sender: {
          firstName: this.currentUser.firstname,
          lastName: this.currentUser.lastname
        }
      };
      this.message = '';
      this.$refs['composer'].focus();
      await this.$_failedMessages_sendAdminPatientChatNote({
        message
      });
    },
    async sendMessage() {
      if (this.messageCategory === 'chat') {
        await this.sendChatMessage();
      } else {
        await this.sendNoteMessage();
      }
      if (!this.conversation.status && !this.conversation.assignee) {
        this.$emit('assign-to-self');
      }
    },
    toggleMessageCategory() {
      this.messageCategory = this.messageCategory === 'chat' ? 'note' : 'chat';
    },
    emitMessageCategory() {
      this.$emit('messageCategoryChanged', this.messageCategory);
    }
  }
};