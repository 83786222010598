import "core-js/modules/es.array.reduce.js";
// libs
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import _capitalize from 'lodash/capitalize';
import _flow from 'lodash/flow';
import _keyBy from 'lodash/keyBy';
import { format } from 'date-fns';

// components
import ChatBubble from '@corefront/components-v2/Chat/ChatBubble';

// constants
import { COMMUNICATION_ROLE, EVENT_CATEGORY, PLATFORM, EVENT_NAME, ENTRY_TYPES, CHAT_TYPE, ROLES, TYPES, JUST_CALL_TYPES, EVENT_KEYS, JUST_CALL_DIRECTIONS } from '@corefront/constant/messages';

// mixins
import { failedMessagesMixin } from '@/mixins/failedMessages';

// utils
import { trimHtmlTags } from '@corefront/utils/trimHtmlTags';
import { now } from '@corefront/utils/now';
export default {
  components: {
    ChatBubble
  },
  mixins: [failedMessagesMixin],
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  computed: {
    // constants
    EVENT_CATEGORY: () => EVENT_CATEGORY,
    COMMUNICATION_ROLE: () => COMMUNICATION_ROLE,
    CHAT_TYPE: () => CHAT_TYPE,
    EVENT_NAME: () => EVENT_NAME,
    ENTRY_TYPES: () => ENTRY_TYPES,
    JUST_CALL_TYPES: () => JUST_CALL_TYPES,
    EVENT_KEYS: () => EVENT_KEYS,
    JUST_CALL_DIRECTIONS: () => JUST_CALL_DIRECTIONS,
    // maps
    ...mapState('chat', ['selectedConversation', 'messages', 'conversations']),
    ...mapState('failedMessages', ['failedMessages']),
    ...mapState('articles', ['articlesDisplay']),
    ...mapGetters('chat', ['conversationPatientName']),
    listeners() {
      return {
        ...this.$listeners
      };
    },
    commonAttrs() {
      return {
        retryFailedFn: this.onRetryFailed,
        deleteFailedFn: this.onDeleteFailed,
        senderName: this.senderName,
        bubbleBackgroundClass: this.bubbleBackgroundClass
      };
    },
    bubbleBackgroundClass() {
      var _this$entry$data$send, _this$entry$data$send2;
      if (((_this$entry$data$send = this.entry.data.sender) === null || _this$entry$data$send === void 0 ? void 0 : _this$entry$data$send.role) === ROLES.ADMIN) {
        return 'tw-bg-pale-blue';
      }
      if (((_this$entry$data$send2 = this.entry.data.sender) === null || _this$entry$data$send2 === void 0 ? void 0 : _this$entry$data$send2.role) === ROLES.PATIENT) {
        return 'tw-bg-silver-gray';
      }
      return null;
    },
    senderName() {
      const {
        entry
      } = this;
      if (entry.data.eventCategory == EVENT_CATEGORY.NOTES) {
        return entry.data.noteBy;
      }
      if (entry.data.eventCategory == EVENT_CATEGORY.SMS) {
        return entry.data.senderName;
      }
      if (entry.data.eventCategory == EVENT_CATEGORY.INTERCOM && entry.data.eventName === EVENT_NAME.INCOMING) {
        const {
          firstName,
          lastName
        } = this.selectedConversation.patient;
        return `${firstName} ${lastName}`;
      }
      if (entry.data.type === TYPES.MESSAGE) {
        return `${entry.data.sender.firstName} ${entry.data.sender.lastName}`;
      }
      return entry.data.senderName;
    },
    timestamp() {
      const {
        entry
      } = this;
      return entry.data.timestampInteraction || entry.data.actionDateTimestamp || entry.data.timestamp || Number(entry.timestamp);
    },
    communicationRole() {
      var _entry$data, _entry$data$patient, _entry$data2, _entry$data2$sender;
      const {
        entry
      } = this;
      const {
        platform,
        eventName,
        eventCategory
      } = entry.data;
      if (platform == PLATFORM.INTERCOM && eventName == EVENT_NAME.OUTGOING) {
        return COMMUNICATION_ROLE.SENDER;
      }
      if (eventCategory == EVENT_CATEGORY.NOTES) {
        return COMMUNICATION_ROLE.SENDER;
      }
      if (eventCategory == EVENT_CATEGORY.SMS && eventName == EVENT_NAME.OUTGOING) {
        return COMMUNICATION_ROLE.SENDER;
      }
      if ((entry === null || entry === void 0 ? void 0 : entry.type) === ENTRY_TYPES.MESSAGE && (entry === null || entry === void 0 ? void 0 : (_entry$data = entry.data) === null || _entry$data === void 0 ? void 0 : (_entry$data$patient = _entry$data.patient) === null || _entry$data$patient === void 0 ? void 0 : _entry$data$patient.id) !== (entry === null || entry === void 0 ? void 0 : (_entry$data2 = entry.data) === null || _entry$data2 === void 0 ? void 0 : (_entry$data2$sender = _entry$data2.sender) === null || _entry$data2$sender === void 0 ? void 0 : _entry$data2$sender.id)) {
        return COMMUNICATION_ROLE.SENDER;
      }
      return COMMUNICATION_ROLE.RECEIVER;
    },
    eventName() {
      const {
        entry
      } = this;
      if (entry.type === ENTRY_TYPES.JUST_CALL) {
        if (entry.data.type === JUST_CALL_TYPES.CALL_COMPLETED) {
          return `${entry.data.direction} Call`;
        }
        if (entry.data.type === JUST_CALL_TYPES.SMS_SENT_RECEIVED) {
          return `${entry.data.direction} SMS`;
        }
      }
      return entry.data.eventName;
    },
    avatarIcon() {
      const {
        entry,
        conversationPatientName
      } = this;
      const {
        data
      } = entry;
      if (data.eventCategory == EVENT_CATEGORY.INTERCOM) {
        if (data.eventName === EVENT_NAME.INCOMING) {
          return conversationPatientName === null || conversationPatientName === void 0 ? void 0 : conversationPatientName.charAt(0).toUpperCase();
        }
        if (data.eventName === EVENT_NAME.OUTGOING) {
          return '👤';
        }
      }
      if (data.eventCategory === EVENT_CATEGORY.CALL_SCHEDULED) {
        return '📞';
      }
      if (data.eventCategory == EVENT_CATEGORY.INFORMATION_UPDATE || data.eventCategory == EVENT_CATEGORY.ADMIN_UPDATE) {
        return '&#128221;';
      }
      switch (data.eventName) {
        case 'Account Created':
          return '&#127874;';
        case 'Visit Started':
        case 'Questionnaire Completed':
        case 'Treatment Chosen':
        case 'Verification Started':
        case 'ID Uploaded':
        case 'Photo Uploaded':
        case 'Checkout Started':
          return '&#9989;';
        case 'ID Upload Skipped':
        case 'Photo Upload Skipped':
        case 'Identity Verification: Denied':
        case 'Follow Up Cancelled':
          return '&#9888;';
        case 'Checkout Complete':
        case 'Onboarding Completed':
          return '&#127881;';
        case 'Patient Accepted':
        case 'Identity Verification: Accepted':
          return '&#129351;';
        case 'Follow Up Started':
        case 'Follow Up Completed':
        case 'Follow Up Sent':
          return '&#128138;';
        case 'Dr Sent a Message':
          return '&#128104; &#9877;';
        case 'Patient Sent a Message':
        case 'Patient Messaged Doctor':
          return '&#128105;';
        case 'Photo Updated':
        case 'ID Updated':
          return '&#128247;';
        case 'Treatment Shipped':
          return '&#x1f4e6;';
        case 'Payment Cleared:':
          return '&#x1f4b5;';
        case 'Payment Failed':
          return '&#9940;';
        default:
          return '&#9889;';
      }
    },
    patientLastSeenTimestamp() {
      var _this$selectedConvers;
      const details = (_this$selectedConvers = this.selectedConversation) === null || _this$selectedConvers === void 0 ? void 0 : _this$selectedConvers.seenBy.find(c => c.type === ROLES.PATIENT);
      return details ? details.timestamp : 0;
    },
    isSeenByPatient() {
      var _this$selectedConvers2, _nextMessage$data$sen;
      if (this.entry.data.type !== TYPES.MESSAGE) {
        return false;
      }
      if (![ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(this.entry.data.sender.role)) {
        return false;
      }
      const details = (_this$selectedConvers2 = this.selectedConversation) === null || _this$selectedConvers2 === void 0 ? void 0 : _this$selectedConvers2.seenBy.find(c => c.type === ROLES.PATIENT);
      if (!details) {
        return false;
      }
      const entryIdx = this.messages.findIndex(message => message.id === this.entry.id);
      const nextMessage = entryIdx ? this.messages[entryIdx - 1] : null;
      const isPatientNextMessage = (nextMessage === null || nextMessage === void 0 ? void 0 : (_nextMessage$data$sen = nextMessage.data.sender) === null || _nextMessage$data$sen === void 0 ? void 0 : _nextMessage$data$sen.role) === ROLES.PATIENT;
      return details.messageId === this.entry.id && !isPatientNextMessage;
    },
    parseMessage() {
      try {
        return JSON.parse(this.entry.data.messageBody);
      } catch (error) {
        return [{
          message: this.entry.data.messageBody,
          status: 'changed'
        }];
      }
    }
  },
  methods: {
    formatBirthday(date) {
      return moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY');
    },
    parseDate(date, dateFormat) {
      if (date) {
        return format(new Date(date), dateFormat);
      }
    },
    getFormLabelByKey(key) {
      switch (key) {
        case 'firstname':
          return 'First Name';
        case 'lastname':
          return 'Last Name';
        case 'phoneno':
          return 'Phone Number';
        case 'address1':
          return 'Street Address';
        case 'address2':
          return 'Apartment/Suite';
        default:
          return key;
      }
    },
    getDoctorReassignedMessage(entry) {
      var _entry$data$doctorFro, _entry$data$doctorFro2, _entry$data$doctorTo, _entry$data$doctorTo2;
      const {
        data
      } = entry;
      const wrapInBold = str => `<span class="tw-text-black tw-font-bold">${str}</span>`;
      const transferrer = data.transferredBy ? `${data.transferredBy.firstName} ${data.transferredBy.lastName}` : data.requestedBy ? `${data.requestedBy.firstName} ${data.requestedBy.lastName}` : '';
      const transferrerInBold = wrapInBold(transferrer ? wrapInBold(transferrer) : '');
      const until = moment.unix(data.endDateStamp || data.actionDateTimestamp).format('MM/DD/YY');
      const doctorFrom = `${(_entry$data$doctorFro = entry.data.doctorFrom) === null || _entry$data$doctorFro === void 0 ? void 0 : _entry$data$doctorFro.firstName} ${(_entry$data$doctorFro2 = entry.data.doctorFrom) === null || _entry$data$doctorFro2 === void 0 ? void 0 : _entry$data$doctorFro2.lastName}`;
      const doctorTo = `${(_entry$data$doctorTo = entry.data.doctorTo) === null || _entry$data$doctorTo === void 0 ? void 0 : _entry$data$doctorTo.firstName} ${(_entry$data$doctorTo2 = entry.data.doctorTo) === null || _entry$data$doctorTo2 === void 0 ? void 0 : _entry$data$doctorTo2.lastName}`;
      const assignment = entry.data.assignmentType === 'PERMANENT' ? 'permanently.' : `until ${wrapInBold(until)}.`;
      const str = `${transferrerInBold} reassigned the doctor from ${wrapInBold(doctorFrom)} to ${wrapInBold(doctorTo)} ${assignment}`;
      return _flow([str => str.trim(), str => _capitalize(str)])(str);
    },
    getArticleData(articleIds) {
      if (!articleIds) {
        return [];
      }
      const articlesById = _keyBy(this.articlesDisplay, 'id');
      return articleIds.reduce((acc, id) => {
        const articleObj = articlesById[id];
        if (!articleObj) {
          return acc;
        }
        return [...acc, {
          id: articleObj.id,
          title: articleObj.title,
          url: articleObj.url,
          bodyNoHtml: trimHtmlTags(articleObj.body)
        }];
      }, []);
    },
    async onDeleteFailed() {
      const confirm = await this.$coreConfirm({
        message: 'Are you sure you want to delete this message?',
        confirmText: 'Delete',
        cancelText: 'Cancel'
      });
      if (!confirm) {
        return;
      }
      const id = this.entry.id;
      this.$_failedMessages_deleteLocalFailedMessage(id);
      await this.$store.dispatch('failedMessages/resolveByMessageId', id);
      this.$store.commit('chat/DELETE_MESSAGE_BY_ID', id);
      this.$store.commit('chat/UPDATE_CONVERSATION_BY_ID', {
        id: this.selectedConversation.id,
        hasFailedMessage: this.failedMessages.length > 0
      });
    },
    async onRetryFailed() {
      const {
        entry
      } = this;
      switch (entry.data.type) {
        case TYPES.MESSAGE:
          await this.$_failedMessages_sendAdminPatientChatMessage({
            message: {
              ...entry.data,
              sentAt: now()
            },
            isRetry: true
          });
          break;
        case TYPES.NOTE:
          await this.$_failedMessages_sendAdminPatientChatNote({
            message: {
              ...entry.data,
              sentAt: now(),
              timestampInteraction: now()
            },
            isRetry: true
          });
          break;
        default:
          break;
      }
      this.$store.commit('chat/UPDATE_CONVERSATION_BY_ID', {
        id: this.selectedConversation.id,
        hasFailedMessage: this.failedMessages.length > 0
      });
    }
  }
};