import { LookupService } from '@/services/lookup.service.js';

export default {
  namespaced: true,
  state: {
    states: [],
  },
  mutations: {
    SET_STATE (state, payload) {
      for (const key in payload) {
        state[key] = payload[key]
      }
    }
  },
  actions: {
    async getStates ({ commit }) {
      try {
        const { data: states } = await LookupService.getStates()

        commit('SET_STATE', {
          states
        })
      } catch (error) {
        console.log(error)
        throw error
      }
    }
  },
};
