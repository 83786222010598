import "core-js/modules/es.array.push.js";
// libs
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import _chunk from 'lodash/chunk';
import _debounce from 'lodash/debounce';

// mixins
import { messageHeightMixin } from '@/mixins/messageHeightMixin';

// utils
import { trimHtmlTags } from '@corefront/utils/trimHtmlTags';

// components
import IconLoading from '@/components/icon/IconLoading.vue';
export default {
  components: {
    IconLoading
  },
  mixins: [messageHeightMixin],
  props: {
    conversation: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isSearching: false,
      filter: '',
      fetchingNextPage: false
    };
  },
  computed: {
    ...mapState('message', ['conversationList', 'conversationListPageNumber', 'conversationPageIndex', 'conversationPages']),
    paginatedConversationList() {
      var _this$conversationPag, _this$conversationPag2;
      if (!((_this$conversationPag = this.conversationPages) !== null && _this$conversationPag !== void 0 && _this$conversationPag.length)) {
        return [];
      }
      return ((_this$conversationPag2 = this.conversationPages[this.conversationPageIndex]) === null || _this$conversationPag2 === void 0 ? void 0 : _this$conversationPag2.items) || [];
    },
    hasNextPage() {
      var _this$conversationPag3, _this$conversationPag4;
      if (!((_this$conversationPag3 = this.conversationPages) !== null && _this$conversationPag3 !== void 0 && _this$conversationPag3.length)) {
        return false;
      }
      return !!((_this$conversationPag4 = this.conversationPages[this.conversationPageIndex]) !== null && _this$conversationPag4 !== void 0 && _this$conversationPag4.nextPage);
    },
    hasPreviousPage() {
      return !!this.conversationPageIndex;
    }
  },
  methods: {
    trimHtmlTags,
    onLoadAllConversations() {
      this.$router.push('/messages');
      this.$emit('fetch-conversations');
    },
    onConversationSearch: _debounce(async function () {
      this.isSearching = !!this.filter;
      if (this.filter) {
        var _conversationPages$;
        const {
          items
        } = await this.$store.dispatch('message/searchConversationList', this.filter);
        const chunked = _chunk(items, 50);
        const conversationPages = chunked.map((items, index) => {
          const id = uuidv4();
          const isLastChunk = index === chunked.length - 1;
          return {
            items,
            nextPage: isLastChunk ? undefined : `next-page-${id}`
          };
        });
        this.$store.commit('message/SET_STATE', {
          conversationPageIndex: 0,
          conversationList: ((_conversationPages$ = conversationPages[0]) === null || _conversationPages$ === void 0 ? void 0 : _conversationPages$.items) || [],
          conversationPages
        });
      } else {
        const {
          items,
          nextPage
        } = await this.$store.dispatch('message/getConversationList');
        if (items.length) {
          this.$store.commit('message/SET_STATE', {
            conversationPages: [{
              items,
              nextPage
            }],
            conversationList: items,
            conversationPageIndex: 0
          });
        }
      }
      this.isSearching = false;
    }, 350),
    async fetchPreviousConversationList() {
      if (this.isSearching || this.fetchingNextPage) {
        return;
      }
      this.$store.commit('message/SET_STATE', {
        conversationPageIndex: this.conversationPageIndex - 1,
        conversationList: this.conversationPages[this.conversationPageIndex - 1].items
      });
      this.$refs.conversationListRef.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    onMarkUnread(conversationid) {
      const conversationList = this.conversationList;
      const idx = conversationList.findIndex(x => x.id == conversationid);
      if (idx > -1) {
        conversationList[idx].unreadcount = true;
      }
      this.$store.commit('message/SET_CONVERSATION', conversationList);
      this.$store.dispatch('message/markAsUnread', {
        conversationid
      });
    },
    async nextConversationList() {
      var _this$conversationPag5, _this$conversationPag6;
      if (!((_this$conversationPag5 = this.conversationPages[this.conversationPageIndex + 1]) !== null && _this$conversationPag5 !== void 0 && (_this$conversationPag6 = _this$conversationPag5.items) !== null && _this$conversationPag6 !== void 0 && _this$conversationPag6.length)) {
        this.fetchingNextPage = true;
        await this.$store.dispatch('message/getConversationList', {
          nextPage: this.conversationPages[this.conversationPageIndex].nextPage
        });
        this.fetchingNextPage = false;
      }
      this.$store.commit('message/SET_STATE', {
        conversationPageIndex: this.conversationPageIndex + 1,
        conversationList: this.conversationPages[this.conversationPageIndex + 1].items
      });
      this.$refs.conversationListRef.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};