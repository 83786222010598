import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class UserConfigrations extends BaseService {
  static async setPatientOutreachFilters (data) {
    try {
      const response = await this
        .request({ auth: true, v2: true })
        .post('/user/conf/patientoutreachfilters', data);

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getPatientOutreachFilters () {
    try {
      const response = await this
        .request({ auth: true, v2: true })
        .get('/user/conf/patientoutreachfilters');

      return new ResponseWrapper(response, response.data);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
