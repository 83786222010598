var render = function render() {
  var _vm$conversation$send;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tw-p-4 tw-flex tw-gap-4 tw-cursor-pointer",
    class: {
      "tw-bg-primary/10": _vm.isSelected && !_vm.conversation.hasFailedMessage,
      "tw-bg-red-500/10": _vm.conversation.hasFailedMessage
    },
    on: {
      click: function ($event) {
        return _vm.$emit("select", _vm.conversation);
      }
    }
  }, [_c("div", {
    staticClass: "tw-relative"
  }, [_vm.isAdmin ? _c("IconForward", {
    staticClass: "tw-text-posey-green"
  }) : _c("CoreAvatar", {
    attrs: {
      color: "evergreen",
      src: _vm.conversation.patient.profileUrl
    }
  }), _vm.conversation.isOnline ? _c("IconCircle", {
    staticClass: "tw-absolute -tw-right-0.5 -tw-top-0.5 tw-text-sea-green"
  }) : _vm._e()], 1), _c("div", {
    staticClass: "tw-flex-1"
  }, [_c("p", {
    staticClass: "tw-font-gerstner tw-text-sm tw-font-bold tw-mb-0"
  }, [_vm._v(" " + _vm._s(_vm.conversation.patient.firstName) + " " + _vm._s(_vm.conversation.patient.lastName) + " ")]), _c("div", [_vm.conversation.lastMessageType === "ATTACHMENT" ? _c("p", {
    staticClass: "tw-mb-0"
  }, [_vm.conversation.lastMessageSenderRole === "PATIENT" ? _c("span", {
    staticClass: "tw-text-sm"
  }, [_vm._v(" Patient sent an attachment ")]) : _vm.currentUser.id === _vm.conversation.sender.id ? _c("span", {
    staticClass: "tw-text-sm"
  }, [_vm._v(" You sent an attachment ")]) : _c("span", {
    staticClass: "tw-text-sm"
  }, [_vm._v(" An admin sent an attachment ")])]) : _vm.conversation.lastMessageSenderRole !== "PATIENT" ? _c("div", {
    staticClass: "tw-mb-0 tw-text-sm tw-flex tw-gap-2 tw-items-center"
  }, [_vm.conversation.lastMessageType === "NOTE" ? _c("div", {
    staticClass: "tw-flex-shrink-0 tw-w-[7px] tw-rounded-full tw-h-[15px] tw-bg-golden-rod tw-inline-block"
  }) : _vm._e(), _c("div", {
    directives: [{
      name: "line-clamp",
      rawName: "v-line-clamp",
      value: 1,
      expression: "1"
    }],
    staticClass: "text-truncated"
  }, [_vm._v(" " + _vm._s((_vm$conversation$send = _vm.conversation.sender) === null || _vm$conversation$send === void 0 ? void 0 : _vm$conversation$send.firstName) + ": " + _vm._s(_vm.trimHtmlTags(_vm.conversation.lastMessage)) + " ")])]) : _c("p", {
    directives: [{
      name: "line-clamp",
      rawName: "v-line-clamp",
      value: 1,
      expression: "1"
    }],
    staticClass: "tw-mb-0 tw-text-sm"
  }, [_vm._v(" " + _vm._s(_vm.trimHtmlTags(_vm.conversation.lastMessage)) + " ")]), _c("div", {
    staticClass: "tw-flex tw-justify-between"
  }, [_c("div", {
    staticClass: "tw-space-x-1 tw-text-gray-100"
  }, [_c("IconChat", {
    staticClass: "tw-text-gray-300 tw-inline"
  }), _c("span", {
    staticClass: "tw-text-gray-500 tw-text-sm"
  }, [_vm._v("Winona Chat")])], 1)])])]), _c("div", {
    staticClass: "tw-flex tw-items-end"
  }, [_c("p", {
    staticClass: "tw-text-sm tw-text-gray-500 tw-mb-0"
  }, [_vm._v(" " + _vm._s(_vm.formatTimeValue) + " ")])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };