var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      size: "md"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("div", [_c("b-alert", {
    attrs: {
      variant: "warning"
    },
    model: {
      value: _vm.alert.show,
      callback: function ($$v) {
        _vm.$set(_vm.alert, "show", $$v);
      },
      expression: "alert.show"
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("span", {
    staticClass: "text-primary"
  }, [_vm._v("Remove from"), _c("br"), _vm._v(" Campaign")])]), _c("b-col", {
    attrs: {
      cols: "9"
    }
  }, [_c("b-row", [_c("b-col", [_c("p", {
    staticClass: "tw-mb-6"
  }, [_vm._v(" Select which campaign to remove the patient from. ")]), _c("b-form-select", {
    staticClass: "tw-mb-6",
    attrs: {
      options: _vm.campaigns
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c("b-form-select-option", {
          attrs: {
            value: null,
            disabled: ""
          }
        }, [_vm._v(" Select Campaign ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedCampaign,
      callback: function ($$v) {
        _vm.selectedCampaign = $$v;
      },
      expression: "selectedCampaign"
    }
  }), _vm.selectedCampaign === "Follow Up" ? _c("div", [_c("p", {
    staticClass: "tw-mb-1 tw-font-bold"
  }, [_vm._v(" Description ")]), _c("p", [_vm._v("This removes patient from the follow up email campaign. The patient will stop receiving email notifications about follow ups.")])]) : _vm._e()], 1)], 1), _c("b-row", {
    staticClass: "mt-4"
  }, [_c("b-col", {
    attrs: {
      offset: "2",
      cols: "10"
    }
  }, [_c("b-row", [_c("b-col", {
    staticClass: "tw-px-1",
    attrs: {
      cols: "6"
    }
  }, [_c("b-button", {
    attrs: {
      block: "",
      variant: "outline-dark",
      pill: "",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Cancel ")])], 1), _c("b-col", {
    staticClass: "tw-px-1",
    attrs: {
      cols: "6"
    }
  }, [_c("b-button", {
    attrs: {
      block: "",
      variant: "secondary",
      pill: "",
      disabled: _vm.buttonDisabled
    },
    on: {
      click: _vm.onRemoveFromCampaign
    }
  }, [_vm._v(" Remove ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };