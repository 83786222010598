import { ChatService } from '@/services/chat.service.js';

export default {
  namespaced: true,
  state: {
    conversationPages: [],
    conversationList: [],
    conversationListNextPage: [],
    conversationListPageNumber: 1,
    conversationPageIndex: 0,
    conversationMembers: [],
    messages: [],
    messagesNextPagePointer: null,
    conversationForwardRequests: [],
  },
  mutations: {
    APPEND_CONVERSATION_PAGE (state, conversationPage) {
      state.conversationPages = [...state.conversationPages, conversationPage];
    },
    APPEND_CONVERSATION (state, conversationList) {
      state.conversationList = [...state.conversationList, ...conversationList];
    },
    UPDATE_CONVERSATION_BY_ID (state, { id, ...properties }) {
      const idx = state.conversationList.findIndex(conversation => {
        return conversation.id === id;
      });

      if (idx == -1) {
        return;
      }

      for (const key in properties) {
        state.conversationList[idx][key] = properties[key];
      }
    },
    UPDATE_MESSAGE_BY_ID (state, { id, ...properties }) {
      const idx = state.messages.findIndex(msg => msg.id === id);

      if (idx == -1) {
        return;
      }

      for (const property in properties) {
        state.messages[idx][property] = properties[property];
      }
    },
    REMOVE_MESSAGE_BY_ID (state, id) {
      const idx = state.messages.findIndex(m => m.id === id);

      state.messages.splice(idx, 1);

      state.messages.__ob__.dep.notify();
    },
    SET_CONVERSATION (state, conversationList) {
      state.conversationList = conversationList;
    },
    SET_CONVERSATION_LIST_NEXT_PAGE (state, conversationListNextPage) {
      state.conversationListNextPage.push(conversationListNextPage);
    },
    SET_CONVERSATION_LIST_PAGE_NUMBER (state, conversationListPageNumber) {
      state.conversationListPageNumber = conversationListPageNumber;
    },
    SET_CONVERSATION_FORWARD_REQUESTS (state, conversationForwardRequests) {
      state.conversationForwardRequests = conversationForwardRequests;
    },
    RESET_STATE (state) {
      state.conversationPages = [];
      state.conversationPageIndex = 0;
    },
    SET_STATE (state, payload) {
      for (const key in payload) {
        state[key] = payload[key];
      }
    },
    APPEND_MESSAGE (state, message) {
      state.messages = [message, ...state.messages];
    },

    DELETE_MESSAGE_BY_ID (state, messageId) {
      const messageIndex = state.messages.findIndex(m => m.id === messageId);

      if (messageIndex === -1) {
        return;
      }

      state.messages.splice(messageIndex, 1);
    },
  },
  getters: {
    conversationDoctor (state) {
      return state.conversationMembers.find(member => {
        return member.type === 'DOCTOR';
      });
    },
  },
  actions: {
    async getConversationList ({ commit }, payload) {
      try {
        const nextPage = payload?.nextPage;
        const { data } = await ChatService.listConversation(nextPage);

        if (nextPage && data?.items?.length) {
          commit('APPEND_CONVERSATION_PAGE', {
            items: data.items,
            nextPage: data?.nextpage,
          });
        }

        return { items: data?.items || [], nextPage: data?.nextpage || '' };
      } catch (error) {
        console.error(error);

        return error;
      }
    },
    async getSingleConversationList ({ commit }, { patientid }) {
      try {
        const { data: conversation } = await ChatService.listSingleConversation(
          patientid
        );
        commit('SET_CONVERSATION', [conversation]);

        return { items: [conversation] || [], nextPage: '' };
      } catch (error) {
        console.error(error);

        return error;
      }
    },
    async getConversationMessages ({ commit }, conversationId) {
      const { data } = await ChatService.listMessage(conversationId);

      commit('SET_STATE', {
        messages: data.items,
        messagesNextPagePointer: data.nextpage || null,
      });

      return data;
    },
    async getConversationNextPageMessages ({ commit, state }, conversationId) {
      const { data } = await ChatService.listMessage(
        conversationId,
        state.messagesNextPagePointer
      );

      const messages = [...state.messages, ...data.items];

      commit('SET_STATE', {
        messages,
        messagesNextPagePointer: data.nextpage || null,
      });

      return messages;
    },
    async getConversationMembers ({ commit }, conversationId) {
      const { data } = await ChatService.listConversationMember(conversationId);

      commit('SET_STATE', {
        conversationMembers: data,
      });
    },
    async searchConversationList ({ commit, dispatch }, searchQuery) {
      try {
        if (searchQuery) {
          const { data } = await ChatService.searchConversation(searchQuery);

          return { items: data || [], nextPage: '' };
        } else {
          commit('RESET_STATE');

          const { items, nextPage } = await dispatch('getConversationList');

          if (items.length) {
            commit('message/SET_STATE', {
              conversationPages: [{ items, nextPage }],
              conversationList: items,
            });
          }
        }
      } catch (error) {
        console.error(error);

        return error;
      }
    },
    async ignoreMessage ({ dispatch }, { conversationid }) {
      await ChatService.ignoreMessage(conversationid);
      dispatch('dashboard/getDashboardData', null, { root: true });
    },
    async snoozeMessage ({ dispatch }, { conversationid }) {
      await ChatService.snoozeMessage(conversationid);
      dispatch('dashboard/getDashboardData', null, { root: true });
    },
    async needPatientReply (context, conversationid) {
      try {
        await ChatService.needPatientReply(conversationid);
      } catch (e) {
        console.error(e);

        return e;
      }
    },
    setConversationListPage ({ commit }, pageNumber) {
      commit('SET_CONVERSATION_LIST_PAGE_NUMBER', pageNumber);
    },
    resetState ({ commit }) {
      commit('RESET_STATE');
    },

    async markAsRead (context, { conversationid, messageid }) {
      return await ChatService.markAsRead(conversationid, messageid);
    },
    async markAsUnread (context, { conversationid }) {
      return await ChatService.markAsUnread(conversationid);
    },
    async getUnreadMessageCount ({ rootState }) {
      try {
        if (rootState?.auth?.currentUser?.id) {
          const { data } = await ChatService.getUnreadMessageCount(
            rootState?.auth?.currentUser?.id
          );

          return data;
        }
      } catch (error) {
        console.error(error);

        return error;
      }
    },

    async forwardRequest ({ dispatch }, { conversationid, payload }) {
      try {
        const { data } = await ChatService.forwardRequest(
          conversationid,
          payload
        );
        dispatch('getConversationForwardRequests', { conversationid });

        return data;
      } catch (error) {
        console.error(error);

        return error;
      }
    },
    async getConversationForwardRequests ({ commit }, { conversationid }) {
      try {
        const { data } = await ChatService.getConversationForwardRequests(
          conversationid
        );
        commit('SET_CONVERSATION_FORWARD_REQUESTS', data);

        return data;
      } catch (error) {
        console.error(error);

        return error;
      }
    },
    async getSingleForwardRequest (
      context,
      { conversationid, forwardedmessageid }
    ) {
      try {
        const { data } = await ChatService.getSingleForwardRequest(
          conversationid,
          forwardedmessageid
        );

        return data;
      } catch (error) {
        console.error(error);

        return error;
      }
    },
  },
};
